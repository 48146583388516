import React, {useState} from 'react';
// eslint-disable-next-line no-restricted-imports
import {Box, Collapse, Modal, ModalBody, ModalCloseButton, ModalContent, Stack, VStack} from '@chakra-ui/react';
import {
  Col,
  Row,
  UiBox,
  UiButton,
  UiCenter,
  UiContainer,
  UiFormControl,
  UiFormLabel,
  UiIcon,
  UiIconButton,
  UiImage,
  UiInput,
  UiRadio,
  UiRadioGroup,
  UiSelect,
  UiSimpleGrid,
  UiText,
  UiTextarea,
} from '~/components/elements';
import {CONTAINER_MAXW} from '~/constants';
import logo from '~/assets/img/logo.svg';
// @ts-ignore
import ReactSlidy from 'react-slidy';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import {useAppDispatch, useInput} from '~/hooks';
import {SurveyApiItem} from '~/types/models/survey';
import {addSurvey} from '~/redux/surveys/thunk';
import {requestOK} from 'redux-thunk-kit';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import {JOB_STATUS_STUDENT, JOB_STATUS_WORKING} from './helpers';

export function SurveyModal({onClose, open}: {onClose: () => void; open: boolean}) {
  const sendDataToGTM = useGTMDispatch();
  const dispatch = useAppDispatch();
  const rules = {
    name: {presence: {message: '^This field is required'}},
    email: {presence: {message: '^This field is required'}, email: {message: '^Email is not valid'}},
    referer: {presence: {message: '^This field is required'}},
    good: {presence: {message: '^This field is required'}},
    bad: {presence: {message: '^This field is required'}},
    job_status: {presence: {message: '^This field is required'}},
    job_title: (value: any, attributes: any, attributeName: any, options: any) => {
      if (attributes.job_status === JOB_STATUS_STUDENT) return null;
      return {
        presence: {message: '^This field is required'},
      };
    },
  };
  const [inputs, setInputs, {validation}] = useInput<Partial<SurveyApiItem>>({job_status: JOB_STATUS_STUDENT}, {rules});
  const [slide, setSlide] = useState(0);

  const onSubmit = async () => {
    const validationErrors = validation.getErrors();
    if (validationErrors) {
      setSlide(0);
      return;
    }
    const resAction = await dispatch(addSurvey({data: inputs}));
    // @TODO with ProjectID!
    if (requestOK(resAction, addSurvey)) {
      onClose();
    }
  };

  return (
    <Modal onClose={onClose} size="full" isOpen={open} scrollBehavior="outside" closeOnOverlayClick={false}>
      <ModalContent>
        <ModalCloseButton top={5} right={10} size="xl" />
        <ModalBody>
          <UiContainer px="10" pb={14} maxW={CONTAINER_MAXW} display="flex" flexDirection="column">
            <Box w="full" pt={5} mb={1}>
              <UiImage src={logo} height="60px" />
            </Box>
            <UiSimpleGrid columns={2} spacing={10}>
              <UiCenter flexDirection="column" flex={1}>
                <UiText textAlign="center" fontSize="34px" fontWeight="bold" mb={10} w="75%">
                  Get 01 FREE code to unblock up to{' '}
                  <UiText as="span" fontSize="34px" color="main.primary">
                    100 activities
                  </UiText>
                  !
                </UiText>
                <UiText textAlign="center" fontSize="14px" w="65%">
                  Thanks for your interest in ToolPM and taking your valued time to taking the survey. <br />
                  <br /> Your provided information is precious for us working to improve ToolPM to serve you and the
                  other users better.
                  <br /> As a token of appreciation, you will receive a FREE credit sent to your email address within 24
                  hours after your survey submission.
                  <br />
                  <br /> All the information gained via this survey is confidential, and is treated with highest
                  security priority by ToolPM for the purpose of optimize ToolPM product and improve user experience
                  only.
                  <br />
                  <br /> If you have any question or request, please don’t hesitate to contact us at support@toolpm.com.
                  <br />
                  <br />
                  <UiText as="span" fontStyle="italic" textAlign="center" fontSize="14px">
                    -- From ToolPM team --
                  </UiText>
                </UiText>
              </UiCenter>
              <Col flex={1} rounded="xl" bg="#FEECB5">
                <Box>
                  <ReactSlidy showArrows={false} slide={slide}>
                    <VStack gap={7} px={14} py={8}>
                      <UiFormControl isInvalid={validation?.errors?.name?.[0]}>
                        <UiFormLabel noOfLines={1}>Your name*</UiFormLabel>
                        <UiInput
                          backgroundColor="white"
                          placeholder="Name"
                          value={inputs.name}
                          onChange={e => setInputs({name: e.target.value})}
                        />
                      </UiFormControl>
                      <UiFormControl isInvalid={validation?.errors?.email?.[0]}>
                        <UiFormLabel noOfLines={1}>Email Address*</UiFormLabel>
                        <UiInput
                          backgroundColor="white"
                          placeholder="Email Address"
                          value={inputs.email}
                          onChange={e => setInputs({email: e.target.value})}
                        />
                      </UiFormControl>
                      <UiFormControl isInvalid={validation?.errors?.referer?.[0]}>
                        <UiFormLabel noOfLines={1}>Where did you hear about us*</UiFormLabel>
                        <UiInput
                          backgroundColor="white"
                          placeholder="Please select the main channel"
                          value={inputs.referer}
                          onChange={e => setInputs({referer: e.target.value})}
                        />
                      </UiFormControl>
                      <UiFormControl isInvalid={validation?.errors?.good?.[0]}>
                        <UiFormLabel noOfLines={1}>Do you find ToolPM useful for your purpose?*</UiFormLabel>
                        <UiInput
                          backgroundColor="white"
                          placeholder="Please help us know what did ToolPM help you"
                          value={inputs.good}
                          onChange={e => setInputs({good: e.target.value})}
                        />
                      </UiFormControl>
                      <UiFormControl isInvalid={validation?.errors?.bad?.[0]}>
                        <UiFormLabel noOfLines={1}>Is there any inconvenience while using ToolPM?*</UiFormLabel>
                        <UiInput
                          backgroundColor="white"
                          placeholder="Please help us know to improve ToolPM"
                          value={inputs.bad}
                          onChange={e => setInputs({bad: e.target.value})}
                        />
                      </UiFormControl>
                      <UiFormControl>
                        <UiFormLabel noOfLines={1}>Do you have any feedback or message for us?</UiFormLabel>
                        <UiTextarea
                          rounded="md"
                          rows={5}
                          backgroundColor="white"
                          placeholder="Let us hear your voice"
                          value={inputs.feedback}
                          onChange={e => setInputs({feedback: e.target.value})}
                        />
                      </UiFormControl>
                    </VStack>
                    <VStack gap={7} px={14} py={8}>
                      <UiFormControl isInvalid={validation?.errors?.job_status?.[0]}>
                        <UiFormLabel noOfLines={1}>Do you work or are you a student?*</UiFormLabel>
                        <UiRadioGroup defaultValue={JOB_STATUS_STUDENT} mt={4} size="lg">
                          <Stack spacing={3} direction="column">
                            <UiRadio
                              value={JOB_STATUS_STUDENT}
                              background="white"
                              borderColor="#C4C4C4"
                              onChange={() => setInputs({job_status: JOB_STATUS_STUDENT})}
                            >
                              <UiText fontSize="14px">I am a student</UiText>
                            </UiRadio>

                            <UiRadio
                              value={JOB_STATUS_WORKING}
                              background="white"
                              borderColor="#C4C4C4"
                              onChange={() => setInputs({job_status: JOB_STATUS_WORKING})}
                            >
                              <UiText fontSize="14px">I am working</UiText>
                            </UiRadio>
                          </Stack>
                        </UiRadioGroup>
                      </UiFormControl>
                      <UiFormControl>
                        <UiFormLabel noOfLines={1}>Do you suggest us to include any other useful tool?</UiFormLabel>
                        <UiInput
                          backgroundColor="white"
                          placeholder="Please pick the tool that you recommend the most"
                          value={inputs.recommend}
                          onChange={e => setInputs({recommend: e.target.value})}
                        />
                      </UiFormControl>
                      <UiBox w="full">
                        <Collapse in={inputs.job_status === JOB_STATUS_WORKING} animateOpacity>
                          <VStack gap={7} px={1}>
                            <UiFormControl isInvalid={validation?.errors?.job_title?.[0]}>
                              <UiFormLabel noOfLines={1}>What is your job title?*</UiFormLabel>
                              <UiInput
                                backgroundColor="white"
                                placeholder="Your job title"
                                value={inputs.job_title}
                                onChange={e => setInputs({job_title: e.target.value})}
                              />
                            </UiFormControl>
                            <UiFormControl>
                              <UiFormLabel noOfLines={1}>Can we know your company size</UiFormLabel>
                              <UiSelect
                                bg="white"
                                placeholder="Company size"
                                onChange={e => setInputs({company_size: e.target.value})}
                              >
                                <option value="1 - 5">1 - 5</option>
                                <option value="6 - 10">6 - 10</option>
                                <option value="11 - 21">11 - 21</option>
                                <option value="21 - 50">21 - 50</option>
                                <option value="Over 50">Over 50</option>
                              </UiSelect>
                            </UiFormControl>
                          </VStack>
                        </Collapse>
                      </UiBox>

                      <UiButton
                        minHeight="40px"
                        px="50px"
                        mx="auto"
                        mt={6}
                        fontSize="14px"
                        variant="solid"
                        onClick={onSubmit}
                      >
                        Complete the survey
                      </UiButton>
                      <UiText textAlign="center" fontSize="14px" fontStyle="italic">
                        You will be receiving the code instantly via your provided email address. <br />
                        If there is any question or support needed, please don’t hesitate to contact us at <br />
                        hello@rayyone.com
                      </UiText>
                    </VStack>
                  </ReactSlidy>
                  <Row justifyContent="center" mb={8} gap={6}>
                    <UiIconButton
                      aria-label="slide1"
                      variant="unstyled"
                      onClick={() => {
                        sendDataToGTM({event: `surveyFormSlide1`});
                        setSlide(0);
                      }}
                      icon={<UiIcon as={FaChevronLeft} color={slide === 0 ? '#CDCCBA' : '#9FABBE'} boxSize="20px" />}
                    />
                    <UiIconButton
                      aria-label="slide2"
                      variant="unstyled"
                      onClick={() => {
                        sendDataToGTM({event: `surveyFormSlide2`});
                        setSlide(1);
                      }}
                      icon={<UiIcon as={FaChevronRight} color={slide === 1 ? '#CDCCBA' : '#9FABBE'} boxSize="20px" />}
                    />
                  </Row>
                </Box>
              </Col>
            </UiSimpleGrid>
          </UiContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
