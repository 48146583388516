import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {PlanEntity} from '~/types/models/plan';

class EntityConfig extends BaseEntityConfig<PlanEntity> {
  entityKey = 'plans';

  associates = [];

  whiteListFields: WhiteListFields<PlanEntity> = ['*'];

  getAccessors = (value: PlanEntity) => {
    //
  };
}

const planEntConf = new EntityConfig();

export const planSchema = planEntConf.getEntitySchema();

export default planEntConf;

export const SOURCES_PLAN = {
  PLANS_PAGE: 'PLANS_PAGE',
  PLANS_HISTORY: 'PLANS_HISTORY',
};
