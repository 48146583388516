import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {ProjectEntity} from '~/types/models/project';

class EntityConfig extends BaseEntityConfig<ProjectEntity> {
  entityKey = 'projects';

  associates = [];

  whiteListFields: WhiteListFields<ProjectEntity> = ['*'];

  getAccessors = (value: ProjectEntity) => {
    //
  };
}

const projectEntConf = new EntityConfig();

export const projectSchema = projectEntConf.getEntitySchema();

export default projectEntConf;

export const SOURCES_PROJECT = {
  PROJECTS_PAGE: 'PROJECTS_PAGE',
};
