import {
  Colors,
  extendTheme,
  ThemeComponents,
  ThemeConfig,
  withDefaultColorScheme,
  withDefaultSize,
} from '@chakra-ui/react';

const fonts = {
  heading: 'Inter',
  body: 'Inter',
};
const colors: Colors = {
  // Base color: #F1A501. Generated by https://smart-swatch.netlify.app
  main: {
    50: '#fff7db',
    100: '#ffe7ad',
    200: '#ffd67d',
    300: '#fec64b',
    400: '#feb61a',
    500: '#e59c01',
    600: '#b27a00',
    700: '#805700',
    800: '#4d3400',
    900: '#1d1000',
    primary: '#F1A501',
    secondary: '#7297A7',
    text: '#212832',
    white: '#FFFFFF',
    border: '#C4C4C4',
    outputBg: '#E6E9F0',
  },
  black: {
    100: '#575757',
    400: '#305853',
  },
  link: {
    active: '#975ca5',
  },
  secondary: {
    50: '#daffff',
    100: '#afffff',
    200: '#80ffff',
    300: '#52ffff',
    400: '#31fefe',
    500: '#24e4e5',
    600: '#12b2b3',
    700: '#007f80',
    800: '#004c4e',
    900: '#001b1c',
  },
};
const fontSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem',
};
const sizes = {
  full: '100%',
  '3xs': '10.2rem',
  '2xs': '12.8rem',
  xs: '15rem',
  sm: '17rem',
  md: '20.4rem',
  lg: '23.6rem',
  xl: '25.8rem',
  '1': '0.2rem',
  '2': '0.4rem',
  '3': '0.6rem',
  '4': '0.8rem',
  '5': '1rem',
  '6': '1.25rem',
  '7': '1.5rem',
  '8': '1.75rem',
  '9': '2rem',
  '10': '2.25rem',
  '12': '2.5rem',
  '14': '3rem',
  '16': '3.5rem',
  '20': '4rem',
  '24': '5rem',
  '28': '6rem',
  '32': '7rem',
  '36': '8rem',
  '40': '9rem',
  '44': '10rem',
  '48': '11rem',
  '52': '12rem',
  '56': '13rem',
  '60': '14rem',
  '64': '15rem',
  '72': '16rem',
  '80': '18rem',
  '96': '20rem',
  px: '24rem',
  '0.5': '1px',
  '1.5': '0.125rem',
  '2.5': '0.375rem',
  '3.5': '0.625rem',
  '2xl': '25.8rem',
  '3xl': '30.6rem',
  '4xl': '35.4rem',
  '5xl': '40.8rem',
  '6xl': '45.2rem',
  '7xl': '50.6rem',
  '8xl': '80rem',
};
const spacing = {
  space: {
    px: '1px',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.3rem',
    2: '0.37rem',
    2.5: '0.4rem',
    3: '0.5rem',
    3.5: '0.7rem',
    4: '0.8rem',
    5: '0.9rem',
    6: '1rem',
    7: '1.5rem',
    8: '1.75rem',
    9: '2rem',
    10: '2.25rem', // 36px
    12: '2.5rem',
    14: '3rem',
    16: '3.5rem',
    20: '4rem',
    24: '5rem',
    28: '6rem',
    32: '7rem',
    36: '8rem',
    40: '9rem',
    44: '10rem',
    48: '11rem',
    52: '12rem',
    56: '13rem',
    60: '14rem',
    64: '15rem',
    72: '16rem',
    80: '17rem',
    96: '19rem',
  },
};

const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
};

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const shadows = {
  outline: '0 0 0 3px #49696b',
};

const components: ThemeComponents = {
  Button: {
    baseStyle: {
      borderRadius: '5px',
    },
    sizes: {
      sm: {
        minW: '40px',
      },
    },
    variants: {
      solid: {
        field: {
          color: 'main.white',
        },
      },
      link: {
        color: 'main.text',
        fontWeight: 'normal',
      },
    },
  },
  Link: {
    baseStyle: {
      textDecoration: 'none',
    },
  },
  FormLabel: {
    baseStyle: {
      fontSize: 'sm',
    },
  },
  Text: {
    baseStyle: {
      fontSize: '12px',
      color: 'main.text',
    },
  },
  Input: {
    baseStyle: {
      field: {
        borderRadius: '5px',
        color: 'main.text', // change the input text color
        minH: '46px',
      },
    },
    variants: {
      outline: {
        field: {
          border: '1px solid',
          borderRadius: '5px',
          borderColor: 'main.border',
          _hover: {
            borderColor: 'main.primary',
          },
          _focus: {
            borderColor: 'main.primary',
          },
          _focusVisible: {
            boxShadow: 'none',
            borderColor: 'main.primary',
          },
        },
      },
    },
  },
  Select: {
    baseStyle: {
      field: {
        borderRadius: '5px',
        color: 'main.text', // change the input text color
        minH: '46px',
        borderColor: 'main.border',
      },
    },
    variants: {
      outline: {
        field: {
          borderRadius: '5px',
          border: '1px solid #C4C4C4',
          _hover: {
            borderColor: 'main.primary',
          },
          _focus: {
            borderColor: 'main.primary',
          },
          _focusVisible: {
            boxShadow: 'none',
            borderColor: 'main.primary',
          },
        },
      },
    },
  },
  Textarea: {
    baseStyle: {
      color: 'main.text', // change the input text color
    },
    variants: {
      outline: {
        border: '1px solid',
        borderColor: 'main.border',
        _hover: {
          borderColor: 'main.primary',
        },
      },
    },
  },
};

export const uiTheme = extendTheme(
  {colors, config, components, shadows, fonts, fontSizes, sizes, ...zIndices, ...spacing},
  withDefaultColorScheme({colorScheme: 'main'}),
  withDefaultSize({size: 'sm'}),
);
