import {createEntityAdapter, createSlice, EntityId} from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import {VoucherEntity} from '~/types/models/voucher';
import {RootState} from '~/redux/root-reducer';
import {
  addIdsToSources,
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  deleteSuccess,
  fetchSuccess,
  ReducerState,
  upsertMany,
  upsertManyMutably,
  upsertOneMutably,
} from 'redux-thunk-kit';
import {SOURCES_VOUCHER} from '~/redux/vouchers/entity-config';
import {addVoucher, deleteVoucher, fetchVouchers, updateVoucher} from '~/redux/vouchers/thunk';

interface InitialState extends ReducerState {}

const sources = mapValues(SOURCES_VOUCHER, () => DEFAULT_SOURCE_REDUCER_STATE);

const vouchersAdapter = createEntityAdapter<VoucherEntity>();

const initialState = vouchersAdapter.getInitialState<InitialState>({sources});

const vouchers = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchVouchers.fulfilled, (state, {payload}) => {
      upsertManyMutably(vouchersAdapter, state, payload.normalized.entities.vouchers);
      fetchSuccess(state, payload);
    });
    builder.addCase(addVoucher.fulfilled, (state, {payload}) => {
      upsertMany(vouchersAdapter, state, payload.normalized.entities.vouchers);
      addIdsToSources(state, payload.normalized.result, [SOURCES_VOUCHER.VOUCHERS_PAGE]);
    });
    builder.addCase(updateVoucher.fulfilled, (state, {payload}) => {
      upsertOneMutably(vouchersAdapter, state, payload.normalized.entities.vouchers);
    });
    builder.addCase(deleteVoucher.fulfilled, (state, {payload, meta}) => {
      const {arg} = meta;
      vouchersAdapter.removeOne(state, arg?.id as EntityId);
      deleteSuccess(state, arg?.id);
    });
  },
});

// export const {} = vouchers.actions;

export default vouchers.reducer;

export const {
  selectById: selectVoucherById,
  selectIds: selectVouchersIds,
  selectEntities: selectVouchersEntities,
  selectAll: selectAllVouchers,
  selectTotal: selectTotalVouchers,
} = vouchersAdapter.getSelectors((state: RootState) => state.vouchers);

export const [getVouchers] = createSelectAllBySource('vouchers', selectVouchersEntities, [
  SOURCES_VOUCHER.VOUCHERS_PAGE,
]);
