import {ENDPOINTS} from '~/constants';
import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';
import {PlanEntity, NormalizedPlan} from '~/types/models/plan';
import {planSchema} from '~/redux/plans/entity-config';
import {thunk} from '~/utils/thunk';

export interface FetchPlansParams extends FetchParams {
  project_id: string;
  order_by?: string;
}

export interface FetchPlanParams extends FetchParams {
  id: string;
}

export interface AddPlanParams extends PostParams {
  data: Partial<PlanEntity>;
}

export interface UpdatePlanParams extends PutParams {
  data: Partial<PlanEntity>;
  id: string;
}

const query = thunk.query<PlanEntity, NormalizedPlan>('plan', planSchema);
export const fetchPlans = query.fetch<FetchPlansParams>(ENDPOINTS.PLANS);
export const addPlan = query.post<AddPlanParams>(ENDPOINTS.PLANS, '', {
  isIgnoreError: false,
  includeApiResponseData: true,
});
export const updatePlan = query.put<UpdatePlanParams>(`${ENDPOINTS.PLANS}/:id`);
export const deletePlan = query.delete<DeleteParams>(`${ENDPOINTS.PLANS}/:id`, '', {withoutNormalize: true});

export const fetchPlan = query.fetchOne<FetchPlanParams>(`${ENDPOINTS.PLANS}/:id`);
