import {ENDPOINTS} from '~/constants';
import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';
import {ProjectEntity, NormalizedProject} from '~/types/models/project';
import {projectSchema} from '~/redux/projects/entity-config';
import {thunk} from '~/utils/thunk';

export interface FetchProjectsParams extends FetchParams {}

export interface FetchProjectParams extends FetchParams {
  id: string;
}

export interface AddProjectParams extends PostParams {
  data: Partial<ProjectEntity>;
}

export interface UpdateProjectParams extends PutParams {
  data: Partial<ProjectEntity>;
  id: string;
}

const query = thunk.query<ProjectEntity, NormalizedProject>('project', projectSchema);
export const fetchProjects = query.fetch<FetchProjectsParams>(ENDPOINTS.PROJECTS);
export const addProject = query.post<AddProjectParams>(ENDPOINTS.PROJECTS);
export const updateProject = query.put<UpdateProjectParams>(`${ENDPOINTS.PROJECTS}/:id`);
export const deleteProject = query.delete<DeleteParams>(`${ENDPOINTS.PROJECTS}/:id`, '', {withoutNormalize: true});

export const fetchProject = query.fetchOne<FetchProjectParams>(`${ENDPOINTS.PROJECTS}/:id`);
