// Let's get things done quickly by this hacky way first
const stage = process.env.REACT_APP_ENV || 'development';

// eslint-disable-next-line import/no-mutable-exports
let environment: {
  STAGE: string;
  SENTRY_DSN: string;
  SENTRY_ENABLED: boolean;
  API: {
    TIMEOUT: number;
    HOST: string;
  };
  GOOGLE_CLIENT_ID: string;
  URL: string;
};
switch (stage) {
  case 'production':
    environment = {
      STAGE: stage,
      SENTRY_DSN: '',
      API: {
        TIMEOUT: 60000,
        HOST: 'https://dooitapi.toolpm.com',
      },
      SENTRY_ENABLED: true,
      GOOGLE_CLIENT_ID: '',
      URL: 'https://toolpm.com',
    };
    break;

  case 'staging':
    environment = {
      STAGE: stage,
      SENTRY_DSN: '',
      API: {
        TIMEOUT: 60000,
        HOST: 'https://dooitapi.toolpm.com',
      },
      SENTRY_ENABLED: true,
      GOOGLE_CLIENT_ID: '',
      URL: 'https://toolpm.com',
    };
    break;

  case 'test':
  case 'development':
  default:
    environment = {
      STAGE: stage,
      SENTRY_DSN: '',
      API: {
        TIMEOUT: 60000,
        HOST: 'http://localhost:9006',
      },
      SENTRY_ENABLED: false,
      GOOGLE_CLIENT_ID: '',
      URL: 'http://localhost:3000',
    };
    break;
}

export default environment;
