import {Button, ButtonProps} from '@chakra-ui/react';
import React, {memo} from 'react';
import {useDelay} from '~/hooks';
import {isStringOrNumber, pascalCase} from '~/utils/helpers';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';

interface Props extends ButtonProps {
  title?: string;
  gaName?: string;
}

const btnProps = {
  _hover: {backgroundColor: 'secondary.500', borderColor: 'secondary.800'},
  _active: {backgroundColor: 'secondary.500'},
};

export const UiButton = memo(
  React.forwardRef<HTMLButtonElement, ButtonProps>(({title, children, onClick, gaName = '', ...rest}: Props, ref) => {
    const sendDataToGTM = useGTMDispatch();

    const bntTitle = title || children;
    const {startDelay, isDelaying} = useDelay(500);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const value = gaName || title || (isStringOrNumber(children) ? children : 'unnamedButton');
      sendDataToGTM({event: `btn${pascalCase(value)}`, value: window?.location?.pathname});

      if (onClick && !isDelaying) {
        onClick(e);
        startDelay();
      }
    };

    return (
      <Button ref={ref} onClick={handleClick} {...rest}>
        {bntTitle}
      </Button>
    );
  }),
);
