// @ts-nocheck
import times from 'lodash/times';
import {InputData, InputDataState, InputDataTable} from '~/types/models/plan';
import max from 'lodash/max';
import {hasDuplicates} from '~/utils/helpers';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';

export const PA_PREFIX = 'pa';
export const MIN_PA_COLS = 3;
export const DURATION_TYPE_1_POINT = '1point';
export const DURATION_TYPE_3_POINTS = '3points';

export const PROJECT_STATUS_PREMIUM = 'premium';
export const JOB_STATUS_STUDENT = 'student';
export const JOB_STATUS_WORKING = 'working';

export const getAnEmptyRawInputData = (id: number) => ({
  id,
  name: undefined,
  pa: [],
  duration_1point: undefined,
  duration_3points: {
    min: undefined,
    max: undefined,
    avg: undefined,
  },
});
// These values must be undefined else it can not populate to the "defaultValue" prop in the MyEditableInput
export const DEFAULT_RAW_INPUT_DATA: InputData[] = [
  {
    id: 1,
    name: 'START',
    pa: [],
    duration_1point: undefined,
    duration_3points: {
      min: undefined,
      max: undefined,
      avg: undefined,
    },
  },
  ...times(9, Number).map(i => getAnEmptyRawInputData(i + 2)),
];

export const getMaxPALength = (raw: InputData[], minCol = MIN_PA_COLS): number =>
  max([...(raw || []).map(i => i.pa.length), minCol])!;

export const inputDataStateToRaw = (inputDataState: InputDataState): InputData[] => Object.values(inputDataState);

export const rawToInputDataState = (raw: InputData[] = []): InputDataState => {
  const clone = [...(raw || [])];
  if (clone.length < 10) {
    for (let i = clone.length; i < 10; i += 1) {
      clone.push(getAnEmptyRawInputData(i + 1));
    }
  }
  return clone.reduce((prev, cur, idx) => {
    prev[cur.id] = cur;
    return prev;
  }, {} as InputDataState);
};

export const rawToInputDataTable = (raw: InputData[] = [], autoFill = true, where = ''): InputDataTable[] => {
  const clone = [...(raw || [])];
  if (autoFill && clone.length < 10) {
    for (let i = clone.length; i < 10; i += 1) {
      clone.push(getAnEmptyRawInputData(i + 1));
    }
  }
  return clone.map((row, idx) => {
    const paObj = row.pa.reduce((accumulate, current, currentIndex) => {
      const paKey = `${PA_PREFIX}${currentIndex}`;
      accumulate[paKey] = current;
      return accumulate;
    }, {} as InputDataTable);

    return {
      ...row,
      ...paObj,
    };
  });
};

export const getTableListeners = (listeners: any) => ({
  onKeyDown: (e: any) => {
    listeners.onKeyDown(e);
    const code = e.which || e.keyCode;
    const charCode = String.fromCharCode(code).toLowerCase();
    if (e.target.tagName === 'TD') {
      if ((e.ctrlKey || e.metaKey) && charCode === 'c') {
        const value = e.target.querySelector('input')?.value || e.target.innerText || '';
        navigator.clipboard.writeText(value);
        return;
      }
      // Delete, backspace
      if (e.keyCode === 8 || e.keyCode === 46) {
        const {code: keyCode, key, view, bubbles} = e.nativeEvent;
        const keyboardEvent = new KeyboardEvent('keydown', {
          code: keyCode,
          key,
          view,
          bubbles,
        });
        const inputEl = e.target.querySelector('input');
        if (inputEl) {
          inputEl.value = '';
          setTimeout(() => {
            inputEl.dispatchEvent(keyboardEvent);
          }, 0);
        }
      }
      // Space, a-z, 0-9
      if (e.keyCode === 32 || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90)) {
        const {code: keyCode, key, view, bubbles} = e.nativeEvent;
        const keyboardEvent = new KeyboardEvent('keydown', {
          code: keyCode,
          key,
          view,
          bubbles,
        });
        const inputEl = e.target.querySelector('input');

        if (inputEl) {
          if (inputEl.getAttribute('type') === 'number' && !(e.keyCode >= 48 && e.keyCode <= 57)) {
            console.log('not a number, do nothing');
          } else {
            inputEl.setAttribute('data-prev', inputEl.value);
            inputEl.setAttribute('data-quickmode', true);
            inputEl.value = '';
            inputEl.focus();
            setTimeout(() => {
              inputEl.dispatchEvent(keyboardEvent);
            }, 0);
          }
        }
      }
    }
  },
  onKeyUp: () => {
    listeners.onKeyUp();
  },
});

export const isDurationHasEmpty = (row: InputData, durationType: string) => {
  if (durationType === DURATION_TYPE_1_POINT) {
    if (row?.duration_1point !== 0) {
      return !row?.duration_1point;
    }
    return false;
  }
  if (durationType === DURATION_TYPE_3_POINTS) {
    return (
      (row?.duration_3points?.min !== 0 && !row?.duration_3points?.min) ||
      (row?.duration_3points?.avg !== 0 && !row?.duration_3points?.avg) ||
      (row?.duration_3points?.max !== 0 && !row?.duration_3points?.max)
    );
  }
  throw new Error('Duration type does not existed');
};

export const validateAndTransformInput = (inputData: InputData[], durationType: string) => {
  let res: InputData[] = [];
  const errors: string[] = [];
  const errorRowIds: number[] = [];
  const validRowIds: number[] = [];

  // eslint-disable-next-line
  for (const row of inputData) {
    const missingDuration = isDurationHasEmpty(row, durationType);
    const isPAEmpty = isPaAllEmpty(row);
    const activityDesc = row.name?.trim();

    if (!activityDesc && isPAEmpty && missingDuration) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (!activityDesc) {
      if (!isPAEmpty || !missingDuration) {
        errors.push(`ID ${row.id}: Missing activity description`);
        errorRowIds.push(row.id);
      }
    }
    if (activityDesc && missingDuration) {
      errors.push(`ID ${row.id}: Missing duration`);
      errorRowIds.push(row.id);
    }

    // Duplicated
    if (hasDuplicates(row.pa)) {
      errors.push(`ID ${row.id}: Duplicated PA is founded`);
      errorRowIds.push(row.id);
    }
    res.push(row);
    validRowIds.push(row.id);
  }
  // eslint-disable-next-line
  for (const row of res) {
    row.pa.forEach(i => {
      // eslint-disable-next-line eqeqeq
      if (i && i == row.id) {
        errors.push(`ID ${row.id}: PA points to current ID`);
        errorRowIds.push(row.id);
      }
      // eslint-disable-next-line eqeqeq
      if (i && i != row.id && validRowIds.indexOf(Number(i)) === -1) {
        errors.push(`ID ${row.id}: PA ${i} does not exist`);
        errorRowIds.push(row.id);
      }
    });
  }

  if (res.length < 2) {
    errors.push('At least 2 tasks are required');
  }
  if (errors.length === 0) {
    // Make sure number are number
    res = res.map(row => ({
      ...row,
      id: Number(row.id),
      duration_1point: Number(row.duration_1point),
      duration_3points: {
        min: Number(row.duration_3points?.min),
        avg: Number(row.duration_3points?.avg),
        max: Number(row.duration_3points?.max),
      },
      pa: row.pa?.map(p => Number(p)),
    }));
  }

  return {data: errors?.length === 0 ? res : null, errors, errorRowIds: uniq(errorRowIds)};
};

export const isPaAllEmpty = (row: InputData) => compact(row.pa)?.length === 0;
