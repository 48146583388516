// @ts-nocheck
import {Col} from '~/components/elements';
import React, {useEffect} from 'react';

export function SidebarAd({side, ...rest}: any) {
  useEffect(() => {
    if (!window.adsbygoogle?.loaded) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <Col w="160px" align={side} hideBelow="md" {...rest}>
      <Col border="0px solid black" h="600px" w="160px">
        <ins
          className="adsbygoogle"
          style={{display: 'inline-block', height: '600px', width: '160px'}}
          data-ad-client="ca-pub-5277983971801539"
          data-ad-slot="6650494909"
        />
      </Col>
    </Col>
  );
}
