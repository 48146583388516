import {appConfig} from '~/config';

export const menu = [
  {
    key: 1,
    title: 'Solutions',
    pages: [
      {key: 1, title: 'Hello', url: ''},
      {key: 2, title: 'Hello', url: ''},
      {key: 3, title: 'Hello', url: ''},
      {key: 4, title: 'Hello', url: ''},
      {key: 5, title: 'Hello', url: ''},
    ],
  },
  {
    key: 2,
    title: 'Resources',
    pages: [
      {key: 1, title: 'Hello', url: ''},
      {key: 2, title: 'Hello', url: ''},
      {key: 3, title: 'Hello', url: ''},
      {key: 4, title: 'Hello', url: ''},
      {key: 5, title: 'Hello', url: ''},
    ],
  },
  {
    key: 3,
    title: appConfig.siteName,
    pages: [
      {key: 1, title: 'Hello', url: ''},
      {key: 2, title: 'Hello', url: ''},
      {key: 3, title: 'Hello', url: ''},
      {key: 4, title: 'Hello', url: ''},
      {key: 5, title: 'Hello', url: ''},
    ],
  },
  {
    key: 4,
    title: 'Useful Links',
    pages: [
      {key: 1, title: 'Hello', url: ''},
      {key: 2, title: 'Hello', url: ''},
      {key: 3, title: 'Hello', url: ''},
      {key: 4, title: 'Hello', url: ''},
      {key: 5, title: 'Hello', url: ''},
    ],
  },
];
