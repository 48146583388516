// eslint-disable-next-line no-restricted-imports
import {InputProps} from '@chakra-ui/react';
import React, {useState} from 'react';
import {UiInput} from '~/components/elements';
import {tableNavigateInCell} from '~/utils/helpers';

interface MyEditableInputProps extends InputProps {
  tableId?: string;
  clickable?: boolean;
}

export function MyEditableInput({tableId, onChange, clickable = true, ...rest}: MyEditableInputProps) {
  // const [isFocused, setIsFocused] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isQuickEditMode, setIsQuickEditMode] = useState(false);
  const [prevValue, setPrevValue] = useState<string | undefined>();
  return (
    <UiInput
      _disabled={{backgroundColor: '#E6E9F0'}}
      p="4px"
      variant="unstyled"
      maxW="100%"
      fontSize="12px"
      minH="35px"
      onKeyDown={e => {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (onChange) {
            // @ts-ignore
            onChange({target: {value: ''}});
          }
        }
        if (isEditable) {
          if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            e.stopPropagation();
          }

          if (e.key === 'Enter') {
            setIsEditable(true);
            const keyboardRightEvent = {
              ...e,
              code: 'ArrowRight',
              key: 'ArrowRight',
              view: window,
              bubbles: true,
            };
            tableNavigateInCell(keyboardRightEvent, `#${tableId}`);
          }
        }

        if (isQuickEditMode) {
          if (e.key === 'ArrowRight' && e.currentTarget.value.length === e.currentTarget.selectionEnd) {
            const keyboardRightEvent = {
              ...e,
              code: 'ArrowRight',
              key: 'ArrowRight',
              view: window,
              bubbles: true,
            };
            tableNavigateInCell(keyboardRightEvent, `#${tableId}`);
          }
          if (e.key === 'ArrowLeft' && e.currentTarget.selectionEnd === 0) {
            const keyboardLeftEvent = {
              ...e,
              code: 'ArrowLeft',
              key: 'ArrowLeft',
              view: window,
              bubbles: true,
            };
            tableNavigateInCell(keyboardLeftEvent, `#${tableId}`);
          }
          if (e.key === 'Escape') {
            if (onChange) {
              // @ts-ignore
              onChange({target: {value: prevValue || ''}});
            }
            e.currentTarget.value = prevValue || '';
          }
        }
      }}
      onClick={e => {
        if (!isEditable) {
          e.preventDefault();
          // @ts-ignore
          e.target.closest('td').focus();
        }
      }}
      onFocus={e => {
        if (!isEditable) {
          if (rest.type !== 'number') {
            e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
          }
          setIsEditable(true);
          setPrevValue(e.target.dataset?.prev);
          setIsQuickEditMode(!!e.target.dataset?.quickmode);
        }
      }}
      onMouseDown={e => {
        if (!isEditable) {
          e.preventDefault();
        }
      }}
      onDoubleClick={e => {
        if (!isEditable && clickable) {
          // @ts-ignore
          e.target.focus();
          if (rest.type !== 'number') {
            e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
          }
          setIsEditable(true);
        }
      }}
      onBlur={e => {
        setIsEditable(false);
        e.currentTarget.setAttribute('data-prev', '');
        e.currentTarget.setAttribute('data-quickmode', '');
        setPrevValue(undefined);
        setIsQuickEditMode(false);
      }}
      _hover={{cursor: isEditable ? undefined : 'default'}}
      marginBottom="0px"
      boxShadow={isEditable ? '0px 1.5px 0px 0px red' : 'none'}
      borderRadius={0}
      onChange={onChange}
      isReadOnly={!isEditable}
      {...rest}
    />
  );
}
