import React from 'react';
import {UiContainer, UiPageLoading, UiSpinner} from '~/components/elements';
import {ContainerProps} from '@chakra-ui/react';
import {CONTAINER_MAXW} from '~/constants';

interface Props extends ContainerProps {
  loading?: boolean;
  basicDiv?: boolean;
  breadcrumbs?: {name: string; path?: string}[];
  children: React.ReactNode;
}

export function AppContainer({loading, basicDiv = false, breadcrumbs, children, ...rest}: Props) {
  if (basicDiv) {
    // @ts-ignore
    return <div {...rest}>{loading ? <UiSpinner size="lg" /> : children}</div>;
  }

  return (
    <UiContainer maxW={CONTAINER_MAXW} {...rest}>
      {loading ? <UiPageLoading /> : children}
    </UiContainer>
  );
}
