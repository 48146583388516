// @ts-nocheck
import {
  Col,
  Row,
  UiBox,
  UiButton,
  UiFormControl,
  UiFormLabel,
  UiIcon,
  UiInput,
  UiText,
  UiTitle,
  UiTooltip,
} from '~/components/elements';
import React, {useEffect, useMemo, useState} from 'react';
import {formatDate} from '~/utils/helpers';
// eslint-disable-next-line no-restricted-imports
import {Alert, AlertIcon, Box, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import {
  DEFAULT_RAW_INPUT_DATA,
  DURATION_TYPE_1_POINT,
  DURATION_TYPE_3_POINTS,
  getMaxPALength,
  getTableListeners,
  PA_PREFIX,
  rawToInputDataTable,
} from '~/pages/home-page/page/helpers';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {Column, useTable} from 'react-table';
import times from 'lodash/times';
import {useAppSelector} from '~/hooks';
import {selectProjectById} from '~/redux/projects/slice';
import {selectPlanById} from '~/redux/plans/slice';
import {InputDataTable} from '~/types/models/plan';
import {useTableNav} from '@table-nav/react';
import maxBy from 'lodash/maxBy';
import moment from '~/services/moment';

const TABLE_ID = 'dooit-result-table';
const FIRST_COL_WIDTH = '42px'; // Can not less than 40px since this is the minimum for this col
const MIN_NUM_COL_WIDTH = '70px';
const MIN_PA_COL_WIDTH = '70px';
export const DESC_MAX_COL_WIDTH = '400px';
export const DESC_MIN_COL_WIDTH = '200px';

export function Result({projectId, onClose}: any) {
  const {listeners} = useTableNav({debug: false});
  const project = useAppSelector(state => selectProjectById(state, projectId));
  const plan = useAppSelector(state => selectPlanById(state, project?.plan_id || ''));
  const [tableOutputData, setTableOutputData] = useState<InputDataTable[]>(
    rawToInputDataTable(plan?.output_data || [], false),
  );
  const [paColumnCount, setPaColumnCount] = useState(getMaxPALength(DEFAULT_RAW_INPUT_DATA, 1));
  const [dateInfo, setDateInfo] = useState({end_date: '', estimated_duration: 0});

  useEffect(() => {
    if (plan?.id) {
      setTableOutputData(rawToInputDataTable(plan.output_data, false));
      setPaColumnCount(getMaxPALength(plan.output_data, 1));
      const estDur = maxBy(plan.output_data, 'early_finish')?.early_finish || 0;

      setDateInfo({
        estimated_duration: estDur,
        end_date: moment(plan?.start_date).add(estDur, 'days'),
      });
    }
  }, [plan, plan?.id, setPaColumnCount]);

  const columns = useMemo<Column<any>[]>(() => {
    const paCols = times(paColumnCount, Number).map(idx => {
      const paKey = `${PA_PREFIX}${idx}`;
      return {
        accessor: paKey,
        cellProps: {minW: MIN_PA_COL_WIDTH},
        // Cell: ({value, row}) => (
        // <MyEditableInput textAlign="center" type="number" value={value} clickable={false} isDisabled />
        Cell: ({row, value}) => <UiText textAlign="center">{value}</UiText>,
        // ),
      };
    });

    const duration3PointsCol = [
      {
        accessor: 'duration_3points.min',
        Cell: ({value, row}) => <UiText textAlign="center">{value}</UiText>,
      },
      {
        accessor: 'duration_3points.avg',
        Cell: ({value, row}) => <UiText textAlign="center">{value}</UiText>,
      },
      {
        accessor: 'duration_3points.max',
        Cell: ({value, row}) => <UiText textAlign="center">{value}</UiText>,
      },
    ];
    const duration1PointCol = {
      accessor: 'duration_1point',
      Cell: ({row, value}) => <UiText textAlign="center">{value}</UiText>,
    };

    const result = [
      {
        accessor: 'id',
        cellProps: {textAlign: 'center', className: 'sticky-col', minW: FIRST_COL_WIDTH, left: 0},
        Cell: ({row, value}) => <UiText textAlign="center">{value}</UiText>,
      },
      {
        accessor: 'name',
        cellProps: {className: 'sticky-col', minW: DESC_MIN_COL_WIDTH, maxW: DESC_MAX_COL_WIDTH, left: FIRST_COL_WIDTH},
        // Cell: ({row, value, ...rest}) => <MyEditableInput value={value} clickable={false} isDisabled />,
        Cell: ({row, value}) => (
          <Col bg={row?.values?.slack === 0 ? '#FDF1C6' : undefined} w="full" h="full" justifyContent="center">
            <UiText px="4px" textAlign="left">
              {value}
            </UiText>
          </Col>
        ),
      },
      ...paCols,
    ];
    // if (plan?.duration_type === DURATION_TYPE_1_POINT) {
    //   result.push(duration1PointCol);
    // }
    // if (plan?.duration_type === DURATION_TYPE_3_POINTS) {
    //   result.push(...duration3PointsCol);
    // }
    result.push(
      ...[
        {
          accessor: 'expected_duration',
          cellProps: {textAlign: 'center', bg: 'main.outputBg'},
          Cell: ({row, value}) => <UiText textAlign="center">{value}</UiText>,
        },
        {
          accessor: 'early_start',
          cellProps: {textAlign: 'center', bg: 'main.outputBg'},
          Cell: ({row, value}) => <UiText textAlign="center">{value}</UiText>,
        },

        {
          accessor: 'early_finish',
          cellProps: {textAlign: 'center', bg: 'main.outputBg'},
          Cell: ({row, value}) => <UiText textAlign="center">{value}</UiText>,
        },
        {
          accessor: 'late_start',
          cellProps: {textAlign: 'center', bg: 'main.outputBg'},
          Cell: ({row, value}) => <UiText textAlign="center">{value}</UiText>,
        },
        {
          accessor: 'late_finish',
          cellProps: {textAlign: 'center', bg: 'main.outputBg'},
          Cell: ({row, value}) => <UiText textAlign="center">{value}</UiText>,
        },
        {
          accessor: 'slack',
          cellProps: {textAlign: 'center', bg: 'main.outputBg'},
          Cell: ({row, value}) => <UiText textAlign="center">{value}</UiText>,
        },
      ],
    );
    return result;
  }, [paColumnCount]);

  const tableInstance = useTable({
    columns,
    data: tableOutputData,
    autoResetHiddenColumns: false,
    autoResetSortBy: false,
    manualSortBy: true,
  });

  const {getTableProps, getTableBodyProps, rows, prepareRow} = tableInstance;

  return (
    <UiBox maxWidth="100%">
      <UiTitle as="h1" fontSize="34px" textAlign="center" width="100%" mx="auto">
        Project:{' '}
        <UiBox as="span" color="main.primary" textTransform="capitalize">
          {project?.name}
        </UiBox>
      </UiTitle>
      <Row mt={7} justifyContent="space-between" wrap="wrap">
        <Col w={{base: '100%', md: '35%'}} mb={{base: 3, md: 0}}>
          <UiFormControl>
            <UiFormLabel noOfLines={1}>Project&apos;s Start Date</UiFormLabel>
            <UiInput isReadOnly mb="10px" type="date" value={formatDate(plan?.start_date)} />
          </UiFormControl>
        </Col>

        <Col w={{base: '100%', md: '35%'}} mb={{base: 3, md: 0}}>
          <UiFormControl>
            <UiFormLabel noOfLines={1}>Estimated End Time</UiFormLabel>
            <UiInput isReadOnly mb="10px" type="date" value={formatDate(dateInfo.end_date)} />
          </UiFormControl>
        </Col>

        <Col w={{base: '100%', md: '25%'}} mb={{base: 3, md: 0}}>
          <UiFormControl>
            <UiFormLabel noOfLines={1}>Estimated Duration (man days)</UiFormLabel>
            <UiInput isReadOnly mb="10px" value={dateInfo.estimated_duration} />
          </UiFormControl>
        </Col>
      </Row>
      <Col alignItems="flex-end" mt="20px">
        <UiText textAlign="right">*Tasks that are on the critical path are highlighted in yellow</UiText>
      </Col>
      <TableContainer maxWidth="100%" pb="10px" mt="10px">
        <Table
          cellSpacing={0}
          id={TABLE_ID}
          // display="block"
          // overflowX="auto"
          size="sm"
          variant="unstyled"
          {...getTableProps()}
          {...getTableListeners(listeners)}
        >
          <Thead>
            <Tr>
              <Th
                className="sticky-col"
                borderLeftWidth="0 !important"
                borderRightWidth="0 !important"
                borderBottomWidth="1px"
                minW={FIRST_COL_WIDTH}
                left={0}
              />
              <Th
                className="sticky-col"
                minW={DESC_MIN_COL_WIDTH}
                maxW={DESC_MAX_COL_WIDTH}
                left={FIRST_COL_WIDTH}
                borderBottomWidth="1px"
                borderRightWidth="0 !important"
              />
              {paColumnCount && paColumnCount > 0 && (
                <Th colSpan={paColumnCount} borderLeftWidth="0 !important" left={0} />
              )}
              {/* {plan?.duration_type === DURATION_TYPE_1_POINT && <Th border="none" />} */}
              {/* {plan?.duration_type === DURATION_TYPE_3_POINTS && ( */}
              {/*  <Th colSpan={3} textAlign="center" bg="main.outputBg" borderTopWidth="1px"> */}
              {/*    <UiTooltip */}
              {/*      hasArrow */}
              {/*      label={ */}
              {/*        <div> */}
              {/*          Min: Optimistic */}
              {/*          <br /> */}
              {/*          Avg: Most likely */}
              {/*          <br /> */}
              {/*          Max: Pessimistic */}
              {/*        </div> */}
              {/*      } */}
              {/*      aria-label="durations"> */}
              {/*      <Box> */}
              {/*        <Row alignItems="center" justifyContent="center"> */}
              {/*          Durations <UiIcon boxSize="15px" as={AiOutlineInfoCircle} /> */}
              {/*        </Row> */}
              {/*      </Box> */}
              {/*    </UiTooltip> */}
              {/*  </Th> */}
              {/* )} */}
              <Th textAlign="center" colSpan={6} bg="main.outputBg" borderTopWidth="1px">
                <UiTooltip
                  hasArrow
                  label={
                    <div>
                      DR: Duration
                      <br />
                      ES: Early Start
                      <br />
                      EF: Early Finish
                      <br />
                      LS: Late Start
                      <br />
                      LF: Late Finish
                    </div>
                  }
                  aria-label="start-finish"
                >
                  <Box>
                    <Row alignItems="center" justifyContent="center">
                      Result <UiIcon boxSize="15px" as={AiOutlineInfoCircle} />
                    </Row>
                  </Box>
                </UiTooltip>
              </Th>
            </Tr>
            <Tr bg="#CDD4DC">
              <Th textAlign="center" className="sticky-col" bg="#CDD4DC" minW={FIRST_COL_WIDTH} left={0}>
                ID
              </Th>
              <Th
                textAlign="center"
                className="sticky-col"
                bg="#CDD4DC"
                minW={DESC_MIN_COL_WIDTH}
                maxW={DESC_MAX_COL_WIDTH}
                left={FIRST_COL_WIDTH}
              >
                Activity Description
              </Th>

              {paColumnCount && paColumnCount > 0 && (
                <Th colSpan={paColumnCount} textAlign="center">
                  PA
                </Th>
              )}
              {/* {plan?.duration_type === DURATION_TYPE_1_POINT && <Th textAlign="center">Duration</Th>} */}
              {/* {plan?.duration_type === DURATION_TYPE_3_POINTS && ( */}
              {/*  <> */}
              {/*    <Th textAlign="center">Min</Th> */}
              {/*    <Th textAlign="center">Avg</Th> */}
              {/*    <Th textAlign="center">Max</Th> */}
              {/*  </> */}
              {/* )} */}
              <Th textAlign="center" minW={MIN_NUM_COL_WIDTH}>
                Duration
              </Th>
              <Th textAlign="center" minW={MIN_NUM_COL_WIDTH}>
                ES
              </Th>
              <Th textAlign="center" minW={MIN_NUM_COL_WIDTH}>
                EF
              </Th>
              <Th textAlign="center" minW={MIN_NUM_COL_WIDTH}>
                LS
              </Th>
              <Th textAlign="center" minW={MIN_NUM_COL_WIDTH}>
                LF
              </Th>
              <Th textAlign="center" minW={MIN_NUM_COL_WIDTH}>
                Slack
              </Th>
            </Tr>
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              const {key, ...restRowProps} = row.getRowProps();
              return (
                <Tr key={key} {...restRowProps}>
                  {row.cells.map((cell, idx) => {
                    const {key: keyCell, ...restCellProps} = cell.getCellProps();
                    // @ts-ignore
                    const {...restColumnProps} = cell.column.cellProps || {};
                    return (
                      <Td
                        key={keyCell}
                        tabIndex={0}
                        verticalAlign="middle"
                        p="0"
                        // borderWidth="1px"
                        role="gridcell"
                        {...restCellProps}
                        {...restColumnProps}
                      >
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Row justifyContent="flex-end" mt={5} alignItems="flex-start">
        {/* <UiButton */}
        {/*  bg="main.primary" */}
        {/*  px={5} */}
        {/*  variant="solid" */}
        {/*  onClick={() => { */}
        {/*    // eslint-disable-next-line no-alert */}
        {/*    alert('Working in Progress'); */}
        {/*  }} */}
        {/* > */}
        {/*  Download Excel File */}
        {/* </UiButton> */}
        <UiButton textDecoration="underline" variant="link" onClick={onClose}>
          Edit Activities
        </UiButton>
      </Row>
      <Stack spacing={3} minH="50px" />
    </UiBox>
  );
}
