import {ENDPOINTS} from '~/constants';
import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';
import {VoucherEntity, NormalizedVoucher} from '~/types/models/voucher';
import {voucherSchema} from '~/redux/vouchers/entity-config';
import {thunk} from '~/utils/thunk';

export interface FetchVouchersParams extends FetchParams {}

export interface FetchVoucherParams extends FetchParams {}

export interface AddVoucherParams extends PostParams {
  data: Partial<VoucherEntity>;
}

export interface UpdateVoucherParams extends PutParams {
  data: Partial<VoucherEntity>;
  id: string;
}

const query = thunk.query<VoucherEntity, NormalizedVoucher>('voucher', voucherSchema);
export const fetchVouchers = query.fetch<FetchVouchersParams>(ENDPOINTS.VOUCHERS);
export const addVoucher = query.post<AddVoucherParams>(ENDPOINTS.VOUCHERS);
export const updateVoucher = query.put<UpdateVoucherParams>(`${ENDPOINTS.VOUCHERS}/:id`);
export const deleteVoucher = query.delete<DeleteParams>(`${ENDPOINTS.VOUCHERS}/:id`, '', {withoutNormalize: true});

export const generateVoucher = query.post<AddVoucherParams>(`${ENDPOINTS.VOUCHERS}/admin`, 'voucherByAdmin', {
  includeApiResponseData: true,
});

export const redeemVoucher = query.post<AddVoucherParams>(`${ENDPOINTS.VOUCHERS}/redeem`, 'redeem', {
  includeApiResponseData: true,
});

export const fetchVoucher = query.fetchOne<FetchVoucherParams>(`${ENDPOINTS.VOUCHERS}/:id`);
