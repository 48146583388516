import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {SurveyEntity} from '~/types/models/survey';

class EntityConfig extends BaseEntityConfig<SurveyEntity> {
  entityKey = 'surveys';

  associates = [];

  whiteListFields: WhiteListFields<SurveyEntity> = ['*'];

  getAccessors = (value: SurveyEntity) => {
    //
  };
}

const surveyEntConf = new EntityConfig();

export const surveySchema = surveyEntConf.getEntitySchema();

export default surveyEntConf;

export const SOURCES_SURVEY = {
  SURVEYS_PAGE: 'SURVEYS_PAGE',
};
