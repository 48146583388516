// @ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Col,
  Row,
  UiBox,
  UiButton,
  UiCenter,
  UiContainer,
  UiFormControl,
  UiFormErrorMessage,
  UiFormHelperText,
  UiFormLabel,
  UiIcon,
  UiInput,
  UiText,
  UiTitle,
  UiTooltip,
} from '~/components/elements';
import {PageLayout} from '~/components/layouts/PageLayout';
import {appConfig} from '~/config';
import {useAppDispatch, useAppSelector, useInput} from '~/hooks';
import {useTableNav} from '@table-nav/react';
import {Column, useTable} from 'react-table';
// eslint-disable-next-line no-restricted-imports
import {
  Alert,
  AlertIcon,
  Box,
  Fade,
  Stack,
  StackItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBoolean,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import {MyEditableInput} from '~/pages/home-page/page/MyEditableInput';
import {useSearchParams} from '~/utils/helpers/react-router-helper';
import {addProject, fetchProject, updateProject} from '~/redux/projects/thunk';
import {requestFailed, requestOK} from 'redux-thunk-kit';
import {selectProjectById} from '~/redux/projects/slice';
import {Duration3Points, InputDataState, InputDataTable, PlanEntity} from '~/types/models/plan';
import {addPlan, fetchPlan, fetchPlans} from '~/redux/plans/thunk';
import {getPlansHistory, selectPlanById} from '~/redux/plans/slice';
import {
  DEFAULT_RAW_INPUT_DATA,
  DURATION_TYPE_1_POINT,
  DURATION_TYPE_3_POINTS,
  getAnEmptyRawInputData,
  getMaxPALength,
  getTableListeners,
  inputDataStateToRaw,
  MIN_PA_COLS,
  PA_PREFIX,
  PROJECT_STATUS_PREMIUM,
  rawToInputDataState,
  rawToInputDataTable,
  validateAndTransformInput,
} from '~/pages/home-page/page/helpers';
import moment from '~/services/moment';
import {CrmAndPert} from '~/pages/home-page/page/CrmAndPert';
import {SidebarAd} from '~/pages/home-page/page/SidebarAd';
import times from 'lodash/times';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {formatDate, formatShortDateTime} from '~/utils/helpers';
import {DESC_MAX_COL_WIDTH, Result} from '~/pages/home-page/page/Result';
import {CONTAINER_MAXW, QUERY_MOBILE} from '~/constants';
import compact from 'lodash/compact';
import {FaExclamation} from 'react-icons/fa';
import {SOURCES_PLAN} from '~/redux/plans/entity-config';
import {RedeemModal} from '~/pages/home-page/page/RedeemModal';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import * as CookieConsent from 'vanilla-cookieconsent';
import {TbDeviceDesktopHeart} from 'react-icons/tb';

const MARGIN_TOP = '20px';
const TABLE_ID = 'dooit-input-table';

function HomePage() {
  const dispatch = useAppDispatch();
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const sendDataToGTM = useGTMDispatch();
  const {listeners} = useTableNav({debug: false});
  const [searchParams, setSearchParams] = useSearchParams();
  const isDebug = searchParams.get('debug') === 'true';
  const [highlightRowId, setHighlightRowId] = useState<number | undefined>(undefined);
  const [errors, setErrors] = useState<string[]>([]);
  const [shouldReRunCols, forceRerunCols] = useState(Date.now());
  const [errorRowIds, setErrorRowIds] = useState<number[]>([]);
  const {onOpen: onOpenRedeemModal, isOpen: isRedeemModalOpen, onClose: onCloseRedeemModal} = useDisclosure();
  const [showResult, setShowResult] = useBoolean(false);

  const rules = {
    name: {presence: {message: "^Project's name is required"}, length: {maximum: 100}},
  };
  const planDataRules = {
    start_date: {presence: {message: 'is required'}},
  };
  const [projectId, setProjectId] = useState(searchParams.get('pi') || '');
  const [planId, setPlanId] = useState(searchParams.get('pli') || '');
  const project = useAppSelector(state => selectProjectById(state, projectId));
  const plan = useAppSelector(state => selectPlanById(state, planId || ''));
  const plansHistory = useAppSelector(getPlansHistory);
  const [projectData, setProjectData, {validation}] = useInput({name: ''}, {rules});
  const [tableInputData, setTableInputData] = useState<InputDataTable[]>(rawToInputDataTable(DEFAULT_RAW_INPUT_DATA));
  const [inputDataState, setInputDataState] = useState<InputDataState>(rawToInputDataState(DEFAULT_RAW_INPUT_DATA));
  const [planInfo, setPlanInfo, {validation: planValidation}] = useInput<
    Pick<Partial<PlanEntity>, 'start_date' | 'duration_type'>
  >({duration_type: DURATION_TYPE_1_POINT, start_date: undefined}, {rules: planDataRules});
  const [paColumnCount, setPaColumnCount] = useState(getMaxPALength(DEFAULT_RAW_INPUT_DATA) || MIN_PA_COLS);

  useEffect(() => {
    const fetchData = async () => {
      const resAction = await dispatch(fetchProject({id: projectId}));
      if (requestFailed(resAction, fetchProject)) {
        if (resAction.payload?.errStatusCode === 404) {
          searchParams.delete('pi');
          setSearchParams(searchParams);
        }
      }
    };
    if (projectId) {
      fetchData();
    }
  }, [dispatch, projectId, searchParams, setSearchParams]);

  useEffect(() => {
    const fetchData = async () => {
      const resAction = await dispatch(fetchPlan({id: planId}));
      if (requestFailed(resAction, fetchPlan)) {
        if (resAction.payload?.errStatusCode === 404) {
          searchParams.delete('pli');
          setSearchParams(searchParams);
        }
      }
    };
    if (planId) {
      fetchData();
    }
  }, [dispatch, planId, searchParams, setSearchParams]);

  useEffect(() => {
    if (projectId && project) {
      setProjectData({name: project.name});
      if (!planId && project.plan_id) {
        setPlanId(project.plan_id);
      }
    }
  }, [planId, project, projectId, setProjectData]);

  useEffect(() => {
    if (plan?.id) {
      setTableInputData(rawToInputDataTable(plan.input_data));
      setInputDataState(rawToInputDataState(plan.input_data));
      setPlanInfo({duration_type: plan.duration_type, start_date: plan.start_date});
      setPaColumnCount(getMaxPALength(plan.input_data));
      forceRerunCols(Date.now());
    }
  }, [plan, plan?.id, setInputDataState, setPlanInfo, setPaColumnCount, setTableInputData, forceRerunCols]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        fetchPlans({project_id: projectId, source: SOURCES_PLAN.PLANS_HISTORY, order_by: 'created_at,asc'}),
      );
    };
    if (isDebug && projectId) {
      fetchData();
    }
  }, [dispatch, isDebug, projectId, planId]);

  useEffect(() => {
    searchParams.set('pi', projectId);
    setSearchParams(searchParams);
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    searchParams.set('pli', planId);
    setSearchParams(searchParams);
    // eslint-disable-next-line
  }, [planId]);

  const onChangePAInput = useCallback((value: string, inputRowId: number, idx: number) => {
    setInputDataState(prev => {
      const newpa = [...prev[inputRowId].pa];
      newpa[idx] = value || undefined;
      return {...prev, [inputRowId]: {...prev?.[inputRowId], pa: newpa}};
    });
    setHighlightRowId(Number(value) || undefined);
  }, []);

  const onChangeDurationInput = useCallback((value: string, inputRowId: number, type: keyof Duration3Points) => {
    setInputDataState(prev => {
      const prevItem = prev?.[inputRowId] || {};
      const newDuration = {...prevItem.duration_3points};
      newDuration[type] = value;
      return {...prev, [inputRowId]: {...prevItem, duration_3points: newDuration}};
    });
  }, []);

  const columns = useMemo<Column<any>[]>(
    () => {
      const paCols = times(paColumnCount, Number).map(idx => {
        const paKey = `${PA_PREFIX}${idx}`;
        return {
          accessor: paKey,
          cellProps: {isPa: true},
          Cell: ({row}) => (
            <MyEditableInput
              textAlign="center"
              type="number"
              min={0}
              isDisabled={row.values.id === 1}
              defaultValue={inputDataState[row.values.id].pa?.[idx]}
              onChange={({target}) => onChangePAInput(target.value, row.values.id, idx)}
            />
          ),
        };
      });

      const duration3PointsCol = [
        {
          accessor: 'duration_3points.min',
          Cell: ({row}) => (
            <MyEditableInput
              textAlign="center"
              defaultValue={inputDataState[row.values.id].duration_3points?.min}
              onChange={({target}) => onChangeDurationInput(target.value, row.values.id, 'min')}
            />
          ),
        },
        {
          accessor: 'duration_3points.avg',
          Cell: ({row}) => (
            <MyEditableInput
              textAlign="center"
              defaultValue={inputDataState[row.values.id].duration_3points?.avg}
              onChange={({target}) => onChangeDurationInput(target.value, row.values.id, 'avg')}
            />
          ),
        },
        {
          accessor: 'duration_3points.max',
          Cell: ({row}) => (
            <MyEditableInput
              textAlign="center"
              defaultValue={inputDataState[row.values.id].duration_3points?.max}
              onChange={({target}) => onChangeDurationInput(target.value, row.values.id, 'max')}
            />
          ),
        },
      ];
      const duration1PointCol = {
        accessor: 'duration_1point',
        Cell: ({row}) => (
          <MyEditableInput
            textAlign="center"
            type="number"
            min={0}
            defaultValue={inputDataState[row.values.id].duration_1point}
            onChange={({target}) => {
              setInputDataState(prev => {
                const inputRowId = row.values.id;
                return {...prev, [inputRowId]: {...prev?.[inputRowId], duration_1point: target.value}};
              });
            }}
          />
        ),
      };

      const result = [
        {
          accessor: 'id',
          cellProps: {textAlign: 'center'},
          Cell: ({row, value}) => (
            <UiCenter pos="relative" w="100%" h="100%">
              {errorRowIds.indexOf(row.values?.id) !== -1 && (
                <UiIcon as={FaExclamation} color="red" boxSize="13px" pos="absolute" right={-2} top={1} />
              )}
              <UiText>{value}</UiText>
            </UiCenter>
          ),
        },
        {
          accessor: 'name',
          Cell: ({row}) => (
            <MyEditableInput
              maxLength={120}
              defaultValue={inputDataState[row.values.id].name}
              onChange={({target}) => {
                setInputDataState(prev => {
                  const inputRowId = row.values.id;
                  return {...prev, [inputRowId]: {...prev?.[inputRowId], name: target.value}};
                });
              }}
            />
          ),
        },
        ...paCols,
      ];
      if (planInfo.duration_type === DURATION_TYPE_1_POINT) {
        result.push(duration1PointCol);
      }
      if (planInfo.duration_type === DURATION_TYPE_3_POINTS) {
        result.push(...duration3PointsCol);
      }
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, planId, paColumnCount, planInfo.duration_type, shouldReRunCols, errorRowIds],
  );
  const tableInstance = useTable({
    columns,
    data: tableInputData,
    autoResetHiddenColumns: false,
    autoResetSortBy: false,
    manualSortBy: true,
  });

  const {getTableProps, getTableBodyProps, rows, prepareRow} = tableInstance;

  const removeFalsyPA = () => {
    const res = Object.values(inputDataState).reduce((prev, cur) => {
      prev[cur.id] = {
        ...cur,
        pa: compact(cur.pa),
      };
      return prev;
    }, {} as InputDataState);
    setInputDataState(res);
    return res;
  };

  const onClickCalculate = async () => {
    setErrors([]);
    setErrorRowIds([]);
    let projectIdParam = projectId;
    const validationErrors = validation.getErrors();
    const planValidationErrors = planValidation.getErrors();
    if (validationErrors || planValidationErrors) {
      setErrors([...Object.values(validationErrors || {}), ...Object.values(planValidationErrors || {})]);
      sendDataToGTM({event: `btnCalculateFailValidation`, value: window?.location?.pathname});
      return;
    }
    if (!projectIdParam) {
      const resAction = await dispatch(addProject({data: {name: projectData.name}}));
      if (requestOK(resAction, addProject)) {
        projectIdParam = resAction?.payload?.normalized?.result;
        setProjectId(projectIdParam);
      } else {
        setErrors(['Can not update project']);
        return;
      }
    }
    const newInputDataState = removeFalsyPA();
    const data = validateAndTransformInput(inputDataStateToRaw(newInputDataState), planInfo.duration_type!);
    if (data.errors?.length > 0) {
      setErrors(data.errors);
      setErrorRowIds(data.errorRowIds);
      return;
    }
    if (data.data) {
      const planParams = {
        project_id: projectIdParam,
        duration_type: planInfo.duration_type,
        start_date: moment(planInfo.start_date)
          .utcOffset(0)
          .set({hour: 0, minute: 0, second: 0, millisecond: 0})
          .toISOString(),
        input_data: data.data,
      };
      window.scrollTo({top: 0, behavior: 'smooth'});
      await dispatch(updateProject({data: {name: projectData.name}, id: projectIdParam}));
      const resAction = await dispatch(addPlan({data: planParams}));
      if (requestOK(resAction, addPlan)) {
        sendDataToGTM({event: `btnCalculateSuccess`, value: window?.location?.pathname});
        setPlanId(resAction?.payload?.normalized?.result);
        setShowResult.on();
      } else {
        sendDataToGTM({event: `btnCalculateFailValidation5xx`, value: window?.location?.pathname});
        setErrors(resAction?.payload?.messageBag || ['Can not add new plan']);
      }
    }
  };

  const openRedeemModal = () => {
    onOpenRedeemModal();
  };

  const onClickAddTask = () => {
    if (project?.status === PROJECT_STATUS_PREMIUM) {
      const raw = inputDataStateToRaw(inputDataState);
      raw.push(getAnEmptyRawInputData(raw.length + 1));
      setInputDataState(rawToInputDataState(raw));
      setTableInputData(rawToInputDataTable(raw));
      forceRerunCols(Date.now());
    } else {
      openRedeemModal();
    }
  };

  const onClickAddPredecessor = () => {
    if (project?.status === PROJECT_STATUS_PREMIUM) {
      setPaColumnCount(paColumnCount + 1);
    } else {
      openRedeemModal();
    }
  };

  const renderPlanHistory = () => {
    const mapData = plansHistory?.data?.map(planHistory => (
      <StackItem key={planHistory.id}>
        <UiButton
          fontWeight={planId === planHistory.id ? 'bold' : 'normal'}
          fontSize="12px"
          textDecoration="underline"
          variant="link"
          onClick={() => {
            setPlanId(planHistory.id);
          }}
        >
          {planHistory.id}
        </UiButton>
        <Row justifyContent="space-between">
          <UiText>{planHistory.duration_type}</UiText>
          <UiText>{formatShortDateTime(planHistory.created_at)}</UiText>
        </Row>
      </StackItem>
    ));

    return (
      <UiBox w="300px" px="15px">
        <VStack mb={8}>
          <UiButton onClick={() => CookieConsent.show(true)}>Display Consent modal</UiButton>
          <UiButton onClick={() => CookieConsent.reset(true)}>Reset Consent Cookies</UiButton>
        </VStack>
        <VStack>{mapData}</VStack>
      </UiBox>
    );
  };

  return (
    <PageLayout
      metaTagsConfig={{
        title: appConfig.siteName,
        description: 'ToolPM',
        metas: [
          {
            name: 'keywords',
            content: 'dooit, toolit',
          },
        ],
      }}
    >
      <UiContainer maxW={CONTAINER_MAXW} minH="100vh" mt={MARGIN_TOP}>
        <Row maxWidth="100%">
          {isDebug ? renderPlanHistory() : <SidebarAd side="flex-end" />}
          <Col flex={1} mx={['15px', '35px']} h="100%" pb="115px" maxWidth="100%" minWidth={0} pos="relative">
            <Fade
              transition={{
                enter: {duration: 0.5, delay: 0.2},
              }}
              in={showResult && !!plan?.output_data}
              style={{
                // position: 'absolute',
                zIndex: showResult && !!plan?.output_data ? 1000 : -1,
                right: 0,
                left: 0,
                backgroundColor: 'white',
                height: showResult && !!plan?.output_data ? 'auto' : '0',
              }}
            >
              <Result
                projectId={projectId}
                onClose={() => {
                  window.scrollTo({top: 310, behavior: 'smooth'});
                  setShowResult.off();
                }}
              />
            </Fade>
            <Fade
              style={{
                backgroundColor: 'white',
                height: showResult && !!plan?.output_data ? '0' : 'auto',
              }}
              in={!showResult}
              transition={{
                enter: {duration: 0.5, delay: 0.2},
              }}
            >
              <UiBox>
                <UiTitle as="h1" fontSize="34px" textAlign="center" width="100%" mx="auto">
                  Get your Critical Path Calculation <br /> & PERT Analysis&nbsp;
                  <UiTitle as="span" fontSize="34px" textAlign="center" mx="auto" color="main.primary">
                    So Real Quick
                  </UiTitle>
                </UiTitle>
              </UiBox>
              <UiBox>
                <UiText fontSize="14px" textAlign="center" mt={7}>
                  Are manual calculations draining your energy, while paid software solutions are too clumsy for you to
                  calculate CPM and PERT?
                  <br /> Let us assist you with our straightforward online tool that requires no log-in. All you need to
                  do is prepare your task list with their sequence and estimation, and we’ll take care of the rest.
                  <br /> If you’re new and want to learn about the benefits of CPM and PERT, check out our blog posts.
                </UiText>
              </UiBox>
              <Row mt={7} justifyContent="space-between" wrap="wrap" hideBelow={QUERY_MOBILE}>
                <Col w={{base: '100%', md: '65%'}} mb={{base: 3, md: 0}}>
                  <UiFormControl isInvalid={validation?.errors?.name[0]}>
                    <UiFormLabel noOfLines={1}>Project&apos;s Name *</UiFormLabel>
                    <UiInput
                      placeholder="Enter the project name."
                      value={projectData.name}
                      onChange={e => setProjectData({name: e.target.value})}
                    />
                    {!validation?.errors?.name ? (
                      <UiFormHelperText>&nbsp;</UiFormHelperText>
                    ) : (
                      <UiFormErrorMessage>{validation?.errors?.name[0]}</UiFormErrorMessage>
                    )}
                  </UiFormControl>
                </Col>

                <Col w={{base: '100%', md: '30%'}} mb={{base: 3, md: 0}}>
                  <UiFormControl isInvalid={planValidation?.errors?.start_date[0]}>
                    <UiFormLabel noOfLines={1}>Project&apos;s Start Date *</UiFormLabel>
                    <UiInput
                      placeholder="Start date *"
                      type="date"
                      value={formatDate(planInfo.start_date)}
                      onChange={e => setPlanInfo({start_date: e.target.value})}
                    />
                    <UiFormErrorMessage>{planValidation?.errors?.start_date[0]}</UiFormErrorMessage>
                  </UiFormControl>
                </Col>
              </Row>
              <Row justifyContent="flex-end" mt={7} hideBelow={QUERY_MOBILE}>
                <UiButton textDecoration="underline" variant="link" onClick={onClickAddPredecessor}>
                  Add Predecessor +
                </UiButton>
                <UiButton
                  textDecoration="underline"
                  ml="70px"
                  mr="30px"
                  variant="link"
                  color={planInfo.duration_type === DURATION_TYPE_1_POINT ? 'main.primary' : undefined}
                  onClick={() => setPlanInfo({duration_type: DURATION_TYPE_1_POINT})}
                >
                  1-point estimation
                </UiButton>
                <UiButton
                  textDecoration="underline"
                  variant="link"
                  color={planInfo.duration_type === DURATION_TYPE_3_POINTS ? 'main.primary' : undefined}
                  onClick={() => setPlanInfo({duration_type: DURATION_TYPE_3_POINTS})}
                >
                  3-points estimation
                </UiButton>
              </Row>
              <UiBox w="100%" mt={5} hideBelow={QUERY_MOBILE}>
                <TableContainer maxWidth="100%" pb="10px">
                  <Table
                    id={TABLE_ID}
                    size="sm"
                    variant="unstyled"
                    cellSpacing={0}
                    {...getTableProps()}
                    {...getTableListeners(listeners)}
                  >
                    <Thead>
                      <Tr>
                        <Th border="none" />
                        <Th border="none" />
                        <Th colSpan={paColumnCount} textAlign="center" bg="main.outputBg">
                          Predecessor Activities
                        </Th>
                        {planInfo.duration_type === DURATION_TYPE_1_POINT && (
                          <Th textAlign="center" bg="main.outputBg">
                            Duration (man days)
                          </Th>
                        )}
                        {planInfo.duration_type === DURATION_TYPE_3_POINTS && (
                          <Th colSpan={3} textAlign="center" bg="main.outputBg">
                            <UiTooltip
                              hasArrow
                              label={
                                <div>
                                  Min: Optimistic
                                  <br />
                                  Avg: Most likely
                                  <br />
                                  Max: Pessimistic
                                </div>
                              }
                              aria-label="durations"
                            >
                              <Box>
                                <Row alignItems="center" justifyContent="center">
                                  Durations (man days) <UiIcon boxSize="15px" as={AiOutlineInfoCircle} />
                                </Row>
                              </Box>
                            </UiTooltip>
                          </Th>
                        )}
                      </Tr>
                      <Tr bg="#CDD4DC">
                        <Th>ID</Th>
                        <Th minWidth={DESC_MAX_COL_WIDTH}>Activity Description</Th>
                        <Th colSpan={paColumnCount} textAlign="center">
                          PA - enter separately in columns
                        </Th>
                        {planInfo.duration_type === DURATION_TYPE_1_POINT && <Th textAlign="center">Duration</Th>}
                        {planInfo.duration_type === DURATION_TYPE_3_POINTS && (
                          <>
                            <Th textAlign="center">Min</Th>
                            <Th textAlign="center">Avg</Th>
                            <Th textAlign="center">Max</Th>
                          </>
                        )}
                      </Tr>
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                      {rows.map(row => {
                        prepareRow(row);
                        const {key, ...restRowProps} = row.getRowProps();
                        return (
                          <Tr
                            key={key}
                            bg={highlightRowId === Number(row.values?.id) ? '#FDF1C6' : undefined}
                            {...restRowProps}
                          >
                            {row.cells.map(cell => {
                              const {key: keyCell, ...restCellProps} = cell.getCellProps();
                              // @ts-ignore
                              const {isPa, ...restColumnProps} = cell.column.cellProps || {};
                              return (
                                <Td
                                  key={keyCell}
                                  onFocus={e => {
                                    // @ts-ignore
                                    if (isPa) {
                                      const inputEl = e.target.querySelector('input');
                                      if (inputEl) {
                                        setHighlightRowId(Number(inputEl.value) || undefined);
                                      }
                                    }
                                  }}
                                  onBlur={() => {
                                    setHighlightRowId(undefined);
                                  }}
                                  tabIndex={0}
                                  verticalAlign="middle"
                                  p="0"
                                  role="gridcell"
                                  {...restCellProps}
                                  {...restColumnProps}
                                >
                                  {cell.render('Cell')}
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Row justifyContent="space-between" mt={3} mb={5} alignItems="flex-start" hideBelow={QUERY_MOBILE}>
                  <UiButton textDecoration="underline" variant="link" onClick={onClickAddTask}>
                    Add activity +
                  </UiButton>
                  <Row justifyContent="flex-end" minW="300px">
                    {!showResult && !!plan?.output_data && (
                      <UiButton
                        _hover={{backgroundColor: 'white'}}
                        variant="outline"
                        borderColor="main.primary"
                        color="main.primary"
                        px={8}
                        onClick={() => {
                          window.scrollTo({top: 0, behavior: 'smooth'});
                          setShowResult.on();
                        }}
                      >
                        Back to Result
                      </UiButton>
                    )}
                    <Col alignItems="flex-end">
                      <UiButton
                        ml="20px"
                        bg="main.primary"
                        px={10}
                        variant="solid"
                        onClick={onClickCalculate}
                        isDisabled={project?.status === PROJECT_STATUS_PREMIUM && project?.attempt_left === 0}
                      >
                        Calculate
                      </UiButton>
                      {project?.status === PROJECT_STATUS_PREMIUM && project?.attempt_left >= 0 && (
                        <UiText mt={2} color="red" fontStyle="italic">
                          {project?.attempt_left} attempts left *
                        </UiText>
                      )}
                    </Col>
                  </Row>
                </Row>
                <Stack spacing={3} minH="50px" hideBelow={QUERY_MOBILE}>
                  {errors &&
                    errors.map((i, idx) => (
                      <Alert key={`${i}-${idx}`} status="error" variant="left-accent">
                        <AlertIcon />
                        <UiText>{i}</UiText>
                      </Alert>
                    ))}
                </Stack>
              </UiBox>
            </Fade>
            {!isDesktop && (
              <UiCenter
                flexDirection="column"
                my="30px"
                py="20px"
                px="10px"
                backgroundColor="main.primary"
                rounded="20px"
              >
                <UiIcon
                  className="animate__animated animate__tada animate__slow animate__infinite"
                  color="white"
                  as={TbDeviceDesktopHeart}
                  boxSize="50"
                />
                <UiText mt="10px" color="white" fontWeight="bold" fontSize="18px" textAlign="center">
                  Switch to Desktop to use our calculation!
                </UiText>
              </UiCenter>
            )}
            <CrmAndPert />
          </Col>
          {isDebug ? null : <SidebarAd side="flex-end" />}
        </Row>
        <RedeemModal onClose={onCloseRedeemModal} open={isRedeemModalOpen} projectId={projectId} />
      </UiContainer>
    </PageLayout>
  );
}

export default HomePage;
