import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {VoucherEntity} from '~/types/models/voucher';

class EntityConfig extends BaseEntityConfig<VoucherEntity> {
  entityKey = 'vouchers';

  associates = [];

  whiteListFields: WhiteListFields<VoucherEntity> = ['*'];

  getAccessors = (value: VoucherEntity) => {
    //
  };
}

const voucherEntConf = new EntityConfig();

export const voucherSchema = voucherEntConf.getEntitySchema();

export default voucherEntConf;

export const SOURCES_VOUCHER = {
  VOUCHERS_PAGE: 'VOUCHERS_PAGE',
};
