import {combineReducers} from '@reduxjs/toolkit';
import ui from '~/redux/ui/slice';
import auth from '~/redux/auth/slice';
import apps from '~/redux/apps/slice';
import users from '~/redux/users/slice';
import projects from '~/redux/projects/slice';
import plans from '~/redux/plans/slice';
import surveys from '~/redux/surveys/slice';
import vouchers from '~/redux/vouchers/slice';
import mailUnsubscribes from '~/redux/mail-unsubscribes/slice';

const appReducer = combineReducers({
  ui,
  apps,
  auth,
  users,
  mailUnsubscribes,
  projects,
  plans,
  surveys,
  vouchers,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
