import {PrivateLayout, PublicLayout} from '~/components/layouts';
import {BasicRoute, PrivateRoute} from '~/components/elements';
import {PAGES} from '~/constants';
import PrivacyPolicyPage from '~/pages/privacy-policy';
import ContactUsPage from '~/pages/contact-us/page/index';
import HomePage from '~/pages/home-page/page/index';
import ContactSubmitted from '~/pages/contact-submitted/index';
import UnsubscribePage from '~/pages/unsubscribe';
import LoginPage from '~/pages/login';

export const publicRoutes: BasicRoute[] = [
  {
    path: '/',
    component: PublicLayout,
    exact: false,
    routes: [
      {path: '/', component: HomePage, exact: true},
      // {path: '/handy-tools', component: LoginPage, exact: true, label: 'Handy Tools'},
      // {path: '/learn', component: LoginPage, exact: true, label: 'Learn'},
      // {path: '/login', component: LoginPage, exact: true, label: 'Login'},
      // {
      //   path: PAGES.CONTACT_US,
      //   exact: true,
      //   component: ContactUsPage,
      // },
      {
        path: PAGES.POLICY,
        exact: true,
        component: PrivacyPolicyPage,
        // label: 'Privacy',
      },
      // {
      //   path: `${PAGES.UNSUBSCRIBE}`,
      //   exact: true,
      //   component: UnsubscribePage,
      // },
      // {path: PAGES.CONTACT_SUBMITTED, exact: true, component: ContactSubmitted},
    ],
  },
];

export const privateRoutes: PrivateRoute[] = [
  {
    path: '/app',
    component: PrivateLayout,
    exact: false,
    routes: [],
  },
];
