import {createEntityAdapter, createSlice, EntityId} from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import {ProjectEntity} from '~/types/models/project';
import {RootState} from '~/redux/root-reducer';
import {
  addIdsToSources,
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  deleteSuccess,
  fetchSuccess,
  ReducerState,
  upsertMany,
  upsertManyMutably,
  upsertOneMutably,
} from 'redux-thunk-kit';
import {SOURCES_PROJECT} from '~/redux/projects/entity-config';
import {addProject, deleteProject, fetchProject, fetchProjects, updateProject} from '~/redux/projects/thunk';

interface InitialState extends ReducerState {}

const sources = mapValues(SOURCES_PROJECT, () => DEFAULT_SOURCE_REDUCER_STATE);

const projectsAdapter = createEntityAdapter<ProjectEntity>();

const initialState = projectsAdapter.getInitialState<InitialState>({sources});

const projects = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProjects.fulfilled, (state, {payload}) => {
      upsertManyMutably(projectsAdapter, state, payload.normalized.entities.projects);
      fetchSuccess(state, payload);
    });
    builder.addCase(addProject.fulfilled, (state, {payload}) => {
      upsertMany(projectsAdapter, state, payload.normalized.entities.projects);
      addIdsToSources(state, payload.normalized.result, [SOURCES_PROJECT.PROJECTS_PAGE]);
    });
    builder.addCase(updateProject.fulfilled, (state, {payload}) => {
      upsertOneMutably(projectsAdapter, state, payload.normalized.entities.projects);
    });
    builder.addCase(deleteProject.fulfilled, (state, {payload, meta}) => {
      const {arg} = meta;
      projectsAdapter.removeOne(state, arg?.id as EntityId);
      deleteSuccess(state, arg?.id);
    });
    builder.addCase(fetchProject.fulfilled, (state, {payload}) => {
      upsertOneMutably(projectsAdapter, state, payload.normalized.entities.projects);
      fetchSuccess(state, payload);
    });
  },
});

// export const {} = projects.actions;

export default projects.reducer;

export const {
  selectById: selectProjectById,
  selectIds: selectProjectsIds,
  selectEntities: selectProjectsEntities,
  selectAll: selectAllProjects,
  selectTotal: selectTotalProjects,
} = projectsAdapter.getSelectors((state: RootState) => state.projects);

export const [getProjects] = createSelectAllBySource('projects', selectProjectsEntities, [
  SOURCES_PROJECT.PROJECTS_PAGE,
]);
