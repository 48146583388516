import React from 'react';
import {Col, Row, UiBox, UiImage, UiText, UiTitle} from '~/components/elements';
import idea from '~/assets/img/idea.svg';
import diagram from '~/assets/img/diagram.svg';
import star from '~/assets/img/star.svg';

export function CrmAndPert() {
  return (
    <UiBox mt="20px">
      <Row mb="50px" rounded="20px" borderWidth="1px" borderColor="main.primary" borderStyle="solid" p={4}>
        <Col mr={4}>
          <UiImage src={idea} height="40px" />
        </Col>
        <Col flex={1}>
          <UiText fontSize="20px" fontWeight="bold" mb={2}>
            Coming soon features:
          </UiText>
          <Row mb={2}>
            <UiImage src={diagram} width="18px" maxW="none" containerStyle={{style: {marginRight: '8px'}}} />
            <UiText fontSize="13px" fontWeight="normal">
              <b>Generate Critical Path Diagram</b> - Visualize your CPM diagram directly on ToolPM & download it as
              Excel file
            </UiText>
          </Row>
          <Row mb={4}>
            <UiImage src={star} width="18px" maxW="none" containerStyle={{style: {marginRight: '8px'}}} />
            <UiText fontSize="13px" fontWeight="normal">
              <b>Payment Scheme</b> - Purchase ToolPM credits to expand as many activities as you want at a reasonable
              price
            </UiText>
          </Row>
        </Col>
      </Row>
      <UiTitle as="h2" fontSize="34px" textAlign="center" width="100%" mx="auto">
        What is CRM and PERT
      </UiTitle>
      <UiBox mt="50px">
        <UiTitle as="h3" fontSize="20px">
          What does CPM stand for and what does it mean
        </UiTitle>
        <UiText fontSize="14px" mt="15px">
          The Critical Path Method (CPM) is a well-known scheduling technique used by project managers during the
          planning phase to identify a project’s crucial tasks. CPM helps project managers estimate the time needed to
          complete the project by identifying the series of activities that take the longest time, also known as the
          critical path. The critical path is vital for schedule planning and control because any changes to it will
          affect the project schedule, helping to prevent conflicts and reduce bottlenecks.
          <br />
          <br />
          CPM was invented and developed by Morgan R. Walker of DuPont and James E. Kelley Jr. of Remington Rand in the
          20th century and was first used in missile-defense construction projects.
          <br /> The Critical Path Method (CPM) has now been widely used in various industries, from large skyscraper
          development to software development.
          <br />
          <br />
          Calculating the Critical Path can be challenging, especially for large projects with many activities and
          complex relationships. Nowadays, most project management leverages software tools to help with this task.
          However, not all software tools offer this feature straightforwardly and user-friendly, and ToolPM is here to
          offer a quick, straightforward solution for you.
        </UiText>
      </UiBox>
      <UiBox mt="50px">
        <UiTitle as="h3" fontSize="20px">
          What does PERT stand for and what does it mean
        </UiTitle>
        <UiText fontSize="14px" mt="15px">
          PERT, abbreviated from Program (Project) Evaluation and Review Technique, is a powerful project management
          tool designed to streamline the planning and control of complex projects. PERT was designed to help managers
          handle large-scale projects that involved intricate task sequences and high levels of uncertainty. It was
          developed as a response to the need for a systematic method to evaluate and review the progress of tasks,
          estimate the time required for project completion, and ensure that the project remains on schedule. The
          technique allows managers to identify the most critical tasks that could impact the project timeline and focus
          resources accordingly.
          <br />
          <br />
          Developed in the late 1950s by the U.S. Navy in collaboration with the Booz Allen Hamilton consulting firm,
          PERT was initially created for managing the Polaris missile submarine program, one of the most complex defense
          projects at the time.
          <br />
          Today, it is widely used in fields like construction, software development, research and development, and any
          industry where projects involve numerous interdependent tasks with varying completion times. PERT is
          particularly valuable in industries with complex, large-scale projects where understanding the
          interdependencies and potential delays is critical to success.
          <br />
          <br />
          Calculating the PERT can be challenging, especially for large projects with many activities and complex
          relationships. Nowadays, most project management leverages software tools to help with this task. However, not
          all software tools offer this feature straightforwardly and user-friendly, and <b>ToolPM</b> is here to offer
          a quick, straightforward solution for you.
        </UiText>
      </UiBox>
      <UiBox mt="50px">
        <UiTitle as="h3" fontSize="20px">
          Comparing CPM and PERT: Which One is Right for Your Project?
        </UiTitle>
        <UiText fontSize="14px" mt="15px">
          When it comes to project management, choosing the right technique to plan, schedule, and control your project
          is crucial. Two of the most popular methods used are the Critical Path Method (CPM) and the Program Evaluation
          and Review Technique (PERT).
          <br /> While both methods are designed to help project managers efficiently manage complex projects, they have
          distinct features, advantages, and disadvantages that make them more suitable for different types of projects.
          This blog post will compare CPM and PERT, highlighting their strengths, weaknesses, and ideal use cases.
        </UiText>
        <UiTitle as="h4" fontSize="14px" my={6}>
          When to Use CPM:
        </UiTitle>
        <UiText fontSize="14px">
          CPM is best suited for projects with well-defined, repetitive tasks where time estimates are predictable.
          Industries like construction, manufacturing, and engineering often rely on CPM due to its straightforward
          approach and ease of implementation. If your project involves tasks that are routine and have fixed durations,
          CPM is likely the most efficient method to use.
        </UiText>
        <UiTitle as="h4" fontSize="14px" my={6}>
          When to Use PERT:
        </UiTitle>
        <UiText fontSize="14px">
          PERT is ideal for projects characterized by high uncertainty and variability in task durations, such as
          research and development or new product development. If your project involves innovative or exploratory tasks
          where outcomes are less predictable, PERT’s ability to handle uncertainty and visualize complex task sequences
          will be invaluable.
        </UiText>
        <UiTitle as="h4" fontSize="14px" my={6}>
          Conclusion:
        </UiTitle>
        <UiText fontSize="14px">
          Both CPM and PERT offer valuable tools for managing projects, but their effectiveness depends on the nature of
          the project. CPM excels in environments with well-defined tasks and predictable durations, while PERT is
          better suited for projects with high levels of uncertainty and complexity. By understanding the strengths and
          weaknesses of each method, project managers can choose the right tool to ensure their projects are completed
          on time and within scope.
        </UiText>
      </UiBox>
    </UiBox>
  );
}
