import {createEntityAdapter, createSlice, EntityId} from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import {SurveyEntity} from '~/types/models/survey';
import {RootState} from '~/redux/root-reducer';
import {
  addIdsToSources,
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  deleteSuccess,
  fetchSuccess,
  ReducerState,
  upsertMany,
  upsertManyMutably,
  upsertOneMutably,
} from 'redux-thunk-kit';
import {SOURCES_SURVEY} from '~/redux/surveys/entity-config';
import {addSurvey, deleteSurvey, fetchSurveys, updateSurvey} from '~/redux/surveys/thunk';

interface InitialState extends ReducerState {}

const sources = mapValues(SOURCES_SURVEY, () => DEFAULT_SOURCE_REDUCER_STATE);

const surveysAdapter = createEntityAdapter<SurveyEntity>();

const initialState = surveysAdapter.getInitialState<InitialState>({sources});

const surveys = createSlice({
  name: 'surveys',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSurveys.fulfilled, (state, {payload}) => {
      upsertManyMutably(surveysAdapter, state, payload.normalized.entities.surveys);
      fetchSuccess(state, payload);
    });
    builder.addCase(addSurvey.fulfilled, (state, {payload}) => {
      upsertMany(surveysAdapter, state, payload.normalized.entities.surveys);
      addIdsToSources(state, payload.normalized.result, [SOURCES_SURVEY.SURVEYS_PAGE]);
    });
    builder.addCase(updateSurvey.fulfilled, (state, {payload}) => {
      upsertOneMutably(surveysAdapter, state, payload.normalized.entities.surveys);
    });
    builder.addCase(deleteSurvey.fulfilled, (state, {payload, meta}) => {
      const {arg} = meta;
      surveysAdapter.removeOne(state, arg?.id as EntityId);
      deleteSuccess(state, arg?.id);
    });
  },
});

// export const {} = surveys.actions;

export default surveys.reducer;

export const {
  selectById: selectSurveyById,
  selectIds: selectSurveysIds,
  selectEntities: selectSurveysEntities,
  selectAll: selectAllSurveys,
  selectTotal: selectTotalSurveys,
} = surveysAdapter.getSelectors((state: RootState) => state.surveys);

export const [getSurveys] = createSelectAllBySource('surveys', selectSurveysEntities, [SOURCES_SURVEY.SURVEYS_PAGE]);
