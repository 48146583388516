// eslint-disable-next-line no-restricted-imports
import {ChakraProvider, ColorModeScript, VStack} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import '~/assets/scss/main.scss';
import {AppRouter, UiButton, UiPageLoading} from '~/components/elements';
import config from '~/config/app-config';
import rootStore from '~/redux/root-store';
import reportWebVitals from '~/reportWebVitals';
import {privateRoutes, publicRoutes} from '~/routes';
import {ignoreLogs} from '~/services/ignore-logs';
import {initSentry} from '~/services/sentry';
import Fonts from '~/theme/chakra-font';
import {uiTheme} from '~/theme/chakra-theme';
import {setup as setupError} from 'ry-error-wrapper';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {appConfig} from '~/config';
import {useAppDispatch, useAppSelector} from '~/hooks';
import * as storage from '~/utils/storage';
import {onLoginSuccessMisc} from '~/redux/auth/thunk';
import {GTMProvider} from '@elgorditosalsero/react-gtm-hook';
import {getCurrentUserFromStorage} from '~/services/auth';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import {getCookie} from 'vanilla-cookieconsent';
import {useSearchParams} from '~/utils/helpers/react-router-helper';

ignoreLogs();

initSentry();

setupError({initSentry, getUser: async () => storage.load('TOOLPM_USER')});

// ReactGA.initialize('UA-123123-1', {debug: false});

function Root() {
  const [injectGtm, setInjectGtm] = useState(false);
  const gtmParams = {id: 'GTM-M8NWPJDT', injectScript: injectGtm};

  useEffect(() => {
    /**
     * All config. options available here:
     * https://cookieconsent.orestbida.com/reference/configuration-reference.html
     */
    CookieConsent.run({
      revision: 1,
      disablePageInteraction: false,
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {
          enabled: true,
          autoClear: {
            cookies: [
              {
                name: /^_ga/, // regex: match all cookies starting with '_ga'
              },
              {
                name: '_gid', // string: exact cookie name
              },
            ],
          },
          services: {
            ga: {
              label: 'Google Analytics',
              cookies: [
                {
                  name: /^(_ga|_gid)/,
                },
              ],
            },
          },
        },
        // gads: {enabled: true},
      },
      onConsent() {
        if (CookieConsent.acceptedService('ga', 'analytics')) {
          setInjectGtm(true);
        }

        if (CookieConsent.acceptedCategory('gads')) {
          // enable google adsense
        }
      },
      onChange: ({cookie, changedCategories, changedServices}) => {
        if (changedCategories.includes('analytics')) {
          if (changedServices.analytics?.includes('ga')) {
            if (CookieConsent.acceptedService('ga', 'analytics')) {
              setInjectGtm(true);
            } else {
              setInjectGtm(false);
            }
          }
        }
        if (changedCategories.includes('gads')) {
          if (CookieConsent.acceptedCategory('gads')) {
            // aa
          } else {
            // bb
          }
        }

        logConsent();
      },
      language: {
        default: 'en',
        translations: {
          en: async () => {
            const res = await fetch('./en.json');
            return res.json();
          },
        },
      },
      guiOptions: {
        consentModal: {
          layout: 'cloud',
          position: 'bottom left',
          flipButtons: false,
          equalWeightButtons: true,
        },
        preferencesModal: {
          layout: 'box',
          // position: 'left right',
          flipButtons: false,
          equalWeightButtons: true,
        },
      },
    });
  }, []);

  const logConsent = () => {
    // Retrieve all the fields
    const cookie = CookieConsent.getCookie();
    const preferences = CookieConsent.getUserPreferences();

    // In this example we're saving only 4 fields
    const userConsent = {
      consentId: cookie.consentId,
      acceptType: preferences.acceptType,
      acceptedCategories: preferences.acceptedCategories,
      rejectedCategories: preferences.rejectedCategories,
    };

    // Send the data to your backend
    // replace "/your-endpoint-url" with your API
    // fetch('/your-endpoint-url', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(userConsent)
    // });
  };
  return (
    <GTMProvider state={gtmParams}>
      <Provider store={rootStore}>
        <GoogleOAuthProvider clientId={appConfig.googleClientId}>
          <ChakraProvider theme={uiTheme}>
            <Fonts />
            <App />
          </ChakraProvider>
        </GoogleOAuthProvider>
      </Provider>
    </GTMProvider>
  );
}

function App() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.auth.currentUser);

  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const init = async () => {
      const {token: savedToken, user: savedUser} = getCurrentUserFromStorage();
      if (savedToken) {
        await dispatch(onLoginSuccessMisc({user: savedUser, token: savedToken}));
      }

      setIsInitializing(false);
    };
    init();
  }, [dispatch]);

  if (isInitializing) {
    return <UiPageLoading />;
  }

  return (
    <AppRouter
      basename={config.routeBaseName}
      authorities={currentUser?.roles}
      privateRoutes={privateRoutes}
      publicRoutes={publicRoutes}
      defaultRedirect="/login"
    />
  );
}

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={uiTheme.config.initialColorMode} />
    <Root />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
