import {unwrapResult} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {PostParams, requestOK} from 'redux-thunk-kit';
import {AuthApiItem, AuthEntity, NormalizedAuth} from '~/types/models/auth';
import {ENDPOINTS} from '~/constants';
import {removeTokenRequestHeader, setTokenRequestHeader} from '~/utils/request';
import * as storage from '~/utils/storage';
import {AppThunk} from '~/redux/root-store';
import {setCurrentUser} from '~/redux/auth/slice';
import {authSchema} from '~/redux/auth/entity-config';
import {saveUserCredential} from '~/services/auth';
import {thunk} from '~/utils/thunk';
import {fetchCurrentUserInfo} from '~/redux/users/thunk';

export interface LoginByEmailParams extends PostParams {
  data: {
    email: string;
    password: string;
  };
}

export interface SocialLoginParams extends PostParams {
  data: {
    provider: string;
    token: string;
    clientId: string;
    name?: string;
    preferredName?: string;
    profilePicture?: string;
  };
}

const query = thunk.query<AuthEntity, NormalizedAuth>('auth', authSchema);

export const onLoginSuccessMisc = query.wrapper<AuthEntity>('onLoginSuccess', async ({token, user}, {dispatch}) => {
  let refreshUser = user;
  setTokenRequestHeader(token.access_token);
  const resAction = await dispatch(fetchCurrentUserInfo({}));
  if (requestOK(resAction, fetchCurrentUserInfo)) {
    // @ts-ignore
    const {apiResponseData} = unwrapResult(resAction);
    refreshUser = apiResponseData;

    await saveUserCredential({user: refreshUser, accessToken: token.access_token, token});

    await dispatch(setCurrentUser({user: refreshUser, token}));
    // @ts-ignore
    Sentry.setUser(refreshUser);
    Sentry.setTag('email', refreshUser?.email);
    return true;
  }
  return true;
});

export const loginByEmail = query.post<LoginByEmailParams>(ENDPOINTS.LOGIN, 'byEmailReq', {
  includeApiResponseData: true,
});
export const socialLogin = query.post<SocialLoginParams>(ENDPOINTS.SOCIAL_LOGIN, 'bySocialReq', {
  includeApiResponseData: true,
});

const parseAuthApiData = (resAction: any): AuthApiItem => {
  const {apiResponseData} = unwrapResult(resAction);
  return apiResponseData;
};

export const loginByEmailMisc = query.wrapper<LoginByEmailParams>('loginByEmailMisc', async (params, {dispatch}) => {
  const resAction = await dispatch(loginByEmail(params));
  if (requestOK(resAction, loginByEmail)) {
    const authApiItem = parseAuthApiData(resAction);
    await dispatch(onLoginSuccessMisc(authApiItem));
  }
});

export const socialLoginMisc = query.wrapper<SocialLoginParams>('loginBySocial', async (params, {dispatch}) => {
  const resAction = await dispatch(socialLogin(params));
  if (requestOK(resAction, socialLogin)) {
    const authApiItem = parseAuthApiData(resAction);
    await dispatch(onLoginSuccessMisc(authApiItem));
  }
});

export const logout = (): AppThunk => async dispatch => {
  await storage.remove('TOOLPM_USER');
  await storage.remove('TOOLPM_ACCESS_TOKEN');
  removeTokenRequestHeader();
  dispatch({type: 'USER_LOGOUT'});
};
