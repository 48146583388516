import {useMediaQuery} from '@chakra-ui/react';
import React, {memo, useEffect, useRef} from 'react';
import {GiHamburgerMenu} from 'react-icons/gi';
import {Link, useHistory, useLocation} from 'react-router-dom';
import logo from '~/assets/img/logo.svg';
import {
  AppContainer,
  BasicRoute,
  Row,
  UiBox,
  UiButton,
  UiGrid,
  UiGridItem,
  UiIcon,
  UiImage,
  UiLink,
} from '~/components/elements';
import {MobileNavigation} from '~/components/layouts/MobileNavigation';
import {QUERY_MOBILE} from '~/constants';
import {useAppDispatch} from '~/hooks';
import {setMenuHeight, showMobileMenu} from '~/redux/ui/slice';
import {publicRoutes} from '~/routes';
import {getCurrentUserFromStorage} from '~/services/auth';

interface Props {}

export const renderMenuItems = (item: BasicRoute, parentPath = '', isMobile = false, cb: any = undefined): any => {
  if (item.routes) {
    if (item.label) {
      // Mean has nested children
      return renderParentMenuItem(item, item.path, isMobile, cb);
    }
    // Mean this is a layout component
    return item.routes.map(route => renderMenuItems(route, parentPath, isMobile, cb));
  }
  if (item.label) {
    const fullPath = parentPath && parentPath !== '/' ? `${parentPath}${item.path}` : item.path;
    return <SingleMenuItem key={fullPath} item={item} parentPath={parentPath} isMobile={isMobile} cb={cb} />;
  }
  return null;
};

const renderParentMenuItem = (item: BasicRoute, parentPath = '', isMobile = false, cb: any = undefined) => (
  <UiBox key={`parent-menu-${item.path}`} _hover={{cursor: 'pointer'}}>
    <Row px={5} alignItems="flex-end">
      <UiLink _hover={{color: 'link.active'}}>{item.label}</UiLink>
    </Row>
  </UiBox>
);

function SingleMenuItem({
  item,
  parentPath = '',
  isMobile = false,
  cb = undefined,
}: {
  item: BasicRoute;
  parentPath: string;
  isMobile: boolean;
  cb: any;
}) {
  const {pathname} = useLocation();

  const fullPath = parentPath && parentPath !== '/' ? `${parentPath}${item.path}` : item.path;
  const isActive = pathname.includes(fullPath);

  if (fullPath !== item.path) {
    // Submenu
    return (
      <UiBox key={`menu-${fullPath}`} fontWeight="bold" my={2} _hover={{cursor: 'pointer'}}>
        <UiLink as={Link} to={fullPath} _hover={{color: 'link.active'}}>
          {item.label}
        </UiLink>
      </UiBox>
    );
  }

  if (isMobile) {
    return (
      <UiBox key={`menu-${fullPath}`} fontWeight="bold" _hover={{cursor: 'pointer'}}>
        <Row alignItems="flex-end" my={5}>
          <UiLink
            onClick={() => cb && cb()}
            as={Link}
            to={fullPath}
            fontSize="xl"
            _focus={{boxShadow: 'none'}}
            _hover={{color: isActive ? undefined : 'teal'}}
            zIndex={2}
          >
            {item.label}
          </UiLink>
        </Row>
        {/* <UiDivider bordercolor="secondary.600" /> */}
      </UiBox>
    );
  }

  return (
    <UiBox key={`menu-${fullPath}`} fontWeight="bold" _hover={{cursor: 'pointer'}}>
      <Row px={5} alignItems="flex-end">
        <UiLink
          as={Link}
          _focus={{boxShadow: 'none'}}
          to={fullPath}
          _hover={{color: isActive ? undefined : 'main.primary'}}
          fontSize={20}
          zIndex={2}
        >
          {item.label}
        </UiLink>
      </Row>
    </UiBox>
  );
}

export const Navigation = memo((props: Props) => {
  const dispatch = useAppDispatch();
  const {user: currentUser} = getCurrentUserFromStorage();
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);
  const [isAtTop, setIsAtTop] = React.useState(true);
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  useEffect(() => {
    dispatch(setMenuHeight({height: ref?.current?.clientHeight!}));
    window.onscroll = () => {
      setIsAtTop(window.scrollY < 10);
    };
  }, [dispatch]);

  return currentUser == null ? null : (
    <UiBox
      ref={ref}
      // pos="fixed"
      right={0}
      left={0}
      top={0}
      bg={!isAtTop ? 'white' : 'transparent'}
      zIndex={999}
      sx={{transition: 'all .3s ease-in'}}
      // borderBottom={!isAtTop ? '2px' : '0'}
      borderStyle="solid"
      borderColor="#f1eee4"
    >
      <AppContainer>
        <UiGrid templateColumns="repeat(10, 1fr)" py={3} gap={6} alignItems="center">
          <UiGridItem colSpan={{base: 12, lg: 2}}>
            <Row align="center">
              <UiLink as={Link} to="/">
                <UiImage src={logo} width={['60px', '112px']} mx="auto" />
              </UiLink>
            </Row>
          </UiGridItem>
          {!isDesktop && <MobileNavigation />}
          {isDesktop && (
            <UiGridItem colStart={4} colEnd={-1}>
              <Row alignItems="center" justifyContent="flex-end">
                {publicRoutes.map(item => renderMenuItems(item))}
                <UiButton
                  paddingX={20}
                  paddingY="20px"
                  fontSize={20}
                  fontWeight="bold"
                  onClick={() => {
                    window.open(
                      'https://docs.google.com/forms/d/e/1FAIpQLSdqeBNCAh-eCsVgNcOc8HBs48A8Hd0oS1RpIw0I-r23RcplAg/viewform',
                    );
                    // history.push(PAGES.CONTACT_US);
                  }}
                >
                  Request More Tools
                </UiButton>
              </Row>
            </UiGridItem>
          )}
        </UiGrid>
      </AppContainer>
    </UiBox>
  );
});
