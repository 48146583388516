import {createEntityAdapter, createSlice, EntityId} from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import {PlanEntity} from '~/types/models/plan';
import {RootState} from '~/redux/root-reducer';
import {
  addIdsToSources,
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  deleteSuccess,
  fetchSuccess,
  ReducerState,
  upsertMany,
  upsertManyMutably,
  upsertOneMutably,
} from 'redux-thunk-kit';
import {SOURCES_PLAN} from '~/redux/plans/entity-config';
import {addPlan, deletePlan, fetchPlan, fetchPlans, updatePlan} from '~/redux/plans/thunk';

interface InitialState extends ReducerState {}

const sources = mapValues(SOURCES_PLAN, () => DEFAULT_SOURCE_REDUCER_STATE);

const plansAdapter = createEntityAdapter<PlanEntity>();

const initialState = plansAdapter.getInitialState<InitialState>({sources});

const plans = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPlans.fulfilled, (state, {payload}) => {
      upsertManyMutably(plansAdapter, state, payload.normalized.entities.plans);
      fetchSuccess(state, payload);
    });
    builder.addCase(addPlan.fulfilled, (state, {payload}) => {
      upsertMany(plansAdapter, state, payload.normalized.entities.plans);
      addIdsToSources(state, payload.normalized.result, [SOURCES_PLAN.PLANS_PAGE]);
    });
    builder.addCase(updatePlan.fulfilled, (state, {payload}) => {
      upsertOneMutably(plansAdapter, state, payload.normalized.entities.plans);
    });
    builder.addCase(deletePlan.fulfilled, (state, {payload, meta}) => {
      const {arg} = meta;
      plansAdapter.removeOne(state, arg?.id as EntityId);
      deleteSuccess(state, arg?.id);
    });
    builder.addCase(fetchPlan.fulfilled, (state, {payload}) => {
      upsertManyMutably(plansAdapter, state, payload.normalized.entities.plans);
      fetchSuccess(state, payload);
    });
  },
});

// export const {} = plans.actions;

export default plans.reducer;

export const {
  selectById: selectPlanById,
  selectIds: selectPlansIds,
  selectEntities: selectPlansEntities,
  selectAll: selectAllPlans,
  selectTotal: selectTotalPlans,
} = plansAdapter.getSelectors((state: RootState) => state.plans);

export const [getPlans, getPlansHistory] = createSelectAllBySource('plans', selectPlansEntities, [
  SOURCES_PLAN.PLANS_PAGE,
  SOURCES_PLAN.PLANS_HISTORY,
]);
