import React from 'react';
// eslint-disable-next-line no-restricted-imports
import {Box, ScaleFade} from '@chakra-ui/react';
import {Col, UiButton, UiIcon, UiText} from '~/components/elements';
import {FaChevronRight} from 'react-icons/fa';

export function PurchaseModal({onClick, open}: {onClick: () => void; open: boolean}) {
  return (
    <ScaleFade
      initialScale={0.1}
      in={open}
      style={{
        position: 'absolute',
        zIndex: open ? 1000 : -1,
        top: -2,
        right: -6,
        left: -8,
        bottom: -10,
        backgroundColor: 'white',
      }}
    >
      <Box>
        <UiText textAlign="center" mt={5} fontSize="24px" fontWeight="bold" mb={8}>
          Our paid scheme will be available soon.
        </UiText>
        <UiText textAlign="center" fontSize="14px" fontWeight="bold">
          Thanks for your interest and support!
        </UiText>
        <UiText textAlign="center" fontSize="14px">
          We are working on to provide the best pricing scheme to you beloved customers.
          <br />
          Please stay tuned and get the best out of ToolPM!
        </UiText>
        <UiText textAlign="center" fontSize="14px" fontWeight="bold" mt={10}>
          Thanks for your interest and support!
        </UiText>
        <Col mt={14}>
          <UiButton minHeight="40px" ml="15px" px="50px" mx="auto" fontSize="14px" variant="solid" onClick={onClick}>
            Earn 01 Free Code <UiIcon as={FaChevronRight} color="white" boxSize="13px" ml={3} />
          </UiButton>
        </Col>
      </Box>
    </ScaleFade>
  );
}
