import React from 'react';
// eslint-disable-next-line no-restricted-imports
import {Box, ScaleFade} from '@chakra-ui/react';
import {Col, UiButton, UiIcon, UiText} from '~/components/elements';
import {FaChevronRight} from 'react-icons/fa';

export function SuccessModal({onClose, open}: {onClose: () => void; open: boolean}) {
  return (
    <ScaleFade
      initialScale={0.1}
      in={open}
      style={{
        position: 'absolute',
        zIndex: open ? 1000 : -1,
        top: -2,
        right: -2,
        left: -2,
        bottom: -10,
        backgroundColor: 'white',
      }}
    >
      <Box>
        <UiText textAlign="center" mt={5} fontSize="24px" fontWeight="bold" mb={8}>
          Code Redeemed Successfully!
        </UiText>
        <UiText textAlign="center" fontSize="14px" fontWeight="bold">
          Thanks for choosing us!
        </UiText>
        <UiText textAlign="center" fontSize="14px">
          Your code has been deemed successfully. You can now expand up to 6 PA columns <br />
          and 100 activity rows. <br />
          Please note that each redemption can be used for 01 calculation only.
        </UiText>
        <Col mt={14}>
          <UiButton
            minHeight="40px"
            ml="15px"
            px="50px"
            mx="auto"
            fontSize="14px"
            variant="solid"
            onClick={() => {
              onClose();
            }}
          >
            Back to calculation <UiIcon as={FaChevronRight} color="white" boxSize="13px" ml={3} />
          </UiButton>
        </Col>
      </Box>
    </ScaleFade>
  );
}
