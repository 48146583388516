import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

export function capitalizeFirstLetter(str: string): string {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function camelCase(str: string): string {
  return (str.slice(0, 1).toLowerCase() + str.slice(1))
    .replace(/([-_ ]){1,}/g, ' ')
    .split(/[-_ ]/)
    .reduce((cur, acc) => cur + acc[0].toUpperCase() + acc.substring(1));
}

export function pascalCase(str: any): string {
  if (!str) {
    str = '';
  }
  return startCase(toLower(String(str))).replaceAll(' ', '');
}

export function snakeCase(str: string) {
  if (!str) {
    return '';
  }
  return str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export function getShortName(fullname?: string) {
  if (!fullname) {
    return '';
  }
  const nameContainer = fullname.split(' ');
  const firstName = nameContainer[0];
  const lengthName = nameContainer.length;
  let lastName = '';
  let shortName = firstName[0];
  if (lengthName > 1) {
    lastName = nameContainer[lengthName - 1];
    shortName += lastName[0];
  }
  return (shortName && shortName.toUpperCase()) || '';
}

export function isInString(haystack: string, needle: string, caseSensitive = false) {
  if (caseSensitive) {
    return haystack.includes(needle);
  }
  return haystack.search(new RegExp(needle, 'i')) !== -1;
}

export function titleCase(str: string) {
  let upper = true;
  let newStr = '';
  str = snakeCase(str).replace(/_/g, ' ');
  for (let i = 0, l = str.length; i < l; i += 1) {
    // Note that you can also check for all kinds of spaces with
    // str[i].match(/\s/)
    if (str[i] === ' ' || str[i] === '_') {
      upper = true;
      newStr += str[i];
      // eslint-disable-next-line no-continue
      continue;
    }
    newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
    upper = false;
  }
  return newStr;
}
