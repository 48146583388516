import {ENDPOINTS} from '~/constants';
import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';
import {SurveyEntity, NormalizedSurvey} from '~/types/models/survey';
import {surveySchema} from '~/redux/surveys/entity-config';
import {thunk} from '~/utils/thunk';

export interface FetchSurveysParams extends FetchParams {}

export interface FetchSurveyParams extends FetchParams {}

export interface AddSurveyParams extends PostParams {
  data: Partial<SurveyEntity>;
}

export interface UpdateSurveyParams extends PutParams {
  data: Partial<SurveyEntity>;
  id: string;
}

const query = thunk.query<SurveyEntity, NormalizedSurvey>('survey', surveySchema);
export const fetchSurveys = query.fetch<FetchSurveysParams>(ENDPOINTS.SURVEYS);
export const addSurvey = query.post<AddSurveyParams>(ENDPOINTS.SURVEYS);
export const updateSurvey = query.put<UpdateSurveyParams>(`${ENDPOINTS.SURVEYS}/:id`);
export const deleteSurvey = query.delete<DeleteParams>(`${ENDPOINTS.SURVEYS}/:id`, '', {withoutNormalize: true});

export const fetchSurvey = query.fetchOne<FetchSurveyParams>(`${ENDPOINTS.SURVEYS}/:id`);
