import React, {useState} from 'react';
// eslint-disable-next-line no-restricted-imports
import {
  AbsoluteCenter,
  Alert,
  AlertIcon,
  Box,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {Col, Row, UiButton, UiImage, UiInput, UiText, UiTitle} from '~/components/elements';
import credit from '~/assets/img/credit.svg';
import paypal from '~/assets/img/paypal.svg';
import payoneer from '~/assets/img/payoneer.svg';
import {SuccessModal} from '~/pages/home-page/page/SuccessModal';
import {PurchaseModal} from '~/pages/home-page/page/PurchaseModal';
import {SurveyModal} from '~/pages/home-page/page/SurveyModal';
import {useAppDispatch} from '~/hooks';
import {ClipboardText} from '~/components/shared/ClipboardText';
import {useSearchParams} from '~/utils/helpers/react-router-helper';
import {generateVoucher, redeemVoucher} from '~/redux/vouchers/thunk';
import {requestOK} from 'redux-thunk-kit';
import {fetchProject} from '~/redux/projects/thunk';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';

export function RedeemModal({onClose, open, projectId}: {onClose: () => void; open: boolean; projectId?: string}) {
  const dispatch = useAppDispatch();
  const sendDataToGTM = useGTMDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDebug = searchParams.get('debug') === 'true';
  const [errors, setErrors] = useState<string[]>([]);

  const {
    onOpen: onOpenRedeemSuccessModal,
    isOpen: isRedeemSuccessModalOpen,
    onClose: onCloseRedeemSuccessModal,
  } = useDisclosure();
  const {onOpen: onOpenPurchaseModal, isOpen: isPurchaseModalOpen, onClose: onClosePurchaseModal} = useDisclosure();
  const {onOpen: onOpenSurveyModal, isOpen: isSurveyModalOpen, onClose: onCloseSurveyModal} = useDisclosure();

  const [redeemCode, setRedeemCode] = useState('');
  const [newVoucherCode, setNewVoucherCode] = useState('');

  const onClickRedeem = async () => {
    const code = redeemCode?.trim();
    if (!code) {
      return;
    }
    if (!projectId) {
      setErrors(['Calculate at least once before redeeming the code.\n']);
      return;
    }

    const resAction = await dispatch(redeemVoucher({data: {project_id: projectId, code: redeemCode}}));
    if (requestOK(resAction, redeemVoucher)) {
      setRedeemCode('');
      await dispatch(fetchProject({id: projectId}));
      sendDataToGTM({event: `btnRedeemSuccess`, value: window?.location?.pathname});
      onOpenRedeemSuccessModal();
    } else {
      sendDataToGTM({event: `btnRedeemFailed`, value: window?.location?.pathname});
      // @ts-ignore
      setErrors(resAction?.payload?.messageBag || ['Can not redeem this code']);
    }
  };

  const onClickGenerateCode = async () => {
    const resAction = await dispatch(generateVoucher({data: {}}));
    if (requestOK(resAction, generateVoucher)) {
      // @ts-ignore
      setNewVoucherCode(resAction.payload?.apiResponseData?.code);
    }
  };

  const onClickPurchase = () => {
    sendDataToGTM({event: `boxBtnPurchase`, value: window?.location?.pathname});
    onOpenPurchaseModal();
  };

  const onClickDoSurvey = () => {
    sendDataToGTM({event: `btnOpenSurveyForm`, value: window?.location?.pathname});
    onOpenSurveyModal();
  };

  const onCloseModal = () => {
    onCloseRedeemSuccessModal();
    onClosePurchaseModal();
    onCloseSurveyModal();
    onClose();
  };

  return (
    <Modal
      onClose={onCloseModal}
      size="xl"
      isOpen={open}
      isCentered
      scrollBehavior="outside"
      closeOnEsc
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent minW="700px" borderRadius="20px">
        <ModalCloseButton top={5} right={5} />
        <ModalBody px={10} py={8}>
          <Col pos="relative">
            <SuccessModal open={isRedeemSuccessModalOpen} onClose={onCloseModal} />
            <SurveyModal open={isSurveyModalOpen} onClose={onCloseModal} />
            <PurchaseModal open={isPurchaseModalOpen} onClick={onClickDoSurvey} />
            <UiText textAlign="center" mt={2} fontSize="24px" fontWeight="bold">
              Enter your code to unblock up to 100 tasks
            </UiText>
            {/* <Row alignItems="center" mt={5}> */}
            {/*  <UiInput */}
            {/*    textTransform="uppercase" */}
            {/*    fontWeight="bold" */}
            {/*    borderColor="main.primary" */}
            {/*    value={redeemCode} */}
            {/*    onChange={e => setRedeemCode(e.target.value)} */}
            {/*  /> */}
            {/*  <UiButton minHeight="46px" ml="15px" px="50px" fontSize="20px" variant="solid" onClick={onClickRedeem}> */}
            {/*    REDEEM */}
            {/*  </UiButton> */}
            {/* </Row> */}
            <Col justifyContent="space-around" alignItems="center" mt={5}>
              <HStack>
                <PinInput
                  type="alphanumeric"
                  size="lg"
                  placeholder=""
                  onChange={value => setRedeemCode(value)}
                  value={redeemCode}
                >
                  <PinInputField
                    height="50px"
                    width="50px"
                    textTransform="uppercase"
                    fontWeight="bold"
                    borderColor="orange"
                  />
                  <PinInputField
                    height="50px"
                    width="50px"
                    textTransform="uppercase"
                    fontWeight="bold"
                    borderColor="orange"
                  />
                  <PinInputField
                    height="50px"
                    width="50px"
                    textTransform="uppercase"
                    fontWeight="bold"
                    borderColor="orange"
                  />
                  <PinInputField
                    height="50px"
                    width="50px"
                    textTransform="uppercase"
                    fontWeight="bold"
                    borderColor="orange"
                  />
                  <PinInputField
                    height="50px"
                    width="50px"
                    textTransform="uppercase"
                    fontWeight="bold"
                    borderColor="orange"
                  />
                  <PinInputField
                    height="50px"
                    width="50px"
                    textTransform="uppercase"
                    fontWeight="bold"
                    borderColor="orange"
                  />
                </PinInput>
              </HStack>
              <UiButton
                minHeight="40px"
                mt="15px"
                px="50px"
                fontSize="20px"
                variant="solid"
                onClick={onClickRedeem}
                isDisabled={redeemCode?.length < 6}
              >
                REDEEM
              </UiButton>
              <Stack spacing={3} mt={5}>
                {errors &&
                  errors.map((i, idx) => (
                    <Alert key={`${i}-${idx}`} status="error" variant="subtle">
                      <AlertIcon />
                      <UiText>{i}</UiText>
                    </Alert>
                  ))}
              </Stack>
            </Col>
            {isDebug && (
              <Row justifyContent="flex-start" alignItems="center" mt={5}>
                <UiButton
                  colorScheme="blue"
                  variant="outline"
                  minHeight="46px"
                  px="10px"
                  fontSize="20px"
                  mr="20px"
                  onClick={onClickGenerateCode}
                >
                  Generate code (Admin)
                </UiButton>
                {!!newVoucherCode && (
                  <ClipboardText
                    text={newVoucherCode}
                    displayText
                    fontSize="20px"
                    fontWeight="bold"
                    isTruncated={false}
                    textTransform="uppercase"
                  />
                )}
              </Row>
            )}

            <Box position="relative" paddingY="8" my={3} w="100%">
              <Divider borderColor="#AFBACA" />
              <AbsoluteCenter bg="white" px="10">
                Or, if you have not yet got one
              </AbsoluteCenter>
            </Box>
            <Row gap="20px">
              <Col
                onClick={onClickPurchase}
                boxShadow="md"
                className="survey-option"
                flex={1}
                border="1px solid #AFBACA"
                borderRadius="20px"
                alignItems="center"
                py={4}
                px={2}
              >
                <UiText textAlign="center" fontWeight="bold" fontSize="24px" mb={1}>
                  Purchase
                </UiText>
                <UiText textAlign="center" fontSize="14px">
                  From only $1. <br />
                  Flexible payment methods.
                </UiText>
                <Row mt={0} height="40px" alignItems="center">
                  <UiImage src={credit} />
                  <UiImage src={paypal} ml="16px" mr="12px" />
                  <UiImage src={payoneer} />
                </Row>
              </Col>
              <Col
                onClick={onClickDoSurvey}
                boxShadow="md"
                className="survey-option"
                flex={1}
                border="1px solid #AFBACA"
                borderRadius="20px"
                alignItems="center"
                py={4}
                px={2}
              >
                <UiText textAlign="center" fontWeight="bold" fontSize="24px" mb={1}>
                  Get 01 Free
                </UiText>
                <UiText textAlign="center" fontSize="14px">
                  Earn 01 free code when taking a <br />
                  2-minutes survey on{' '}
                  <UiTitle as="span" fontSize="14px" color="main.primary">
                    ToolPM!
                  </UiTitle>
                  <br />
                  Code sent instantly to your email.
                </UiText>
              </Col>
            </Row>
          </Col>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
