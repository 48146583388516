import React, {useState} from 'react';
import {AiOutlineCopy} from 'react-icons/ai';
import {Row, UiIcon, UiText, UiTooltip} from '~/components/elements';
import {IoMdCopy} from 'react-icons/io';
import {Box, TextProps} from '@chakra-ui/react';
import {IconType} from 'react-icons';

interface Props extends TextProps {
  text: string;
  isTruncated?: boolean;
  letterDisplayed?: number;
  icon?: IconType;
  displayText?: boolean;
}

/**
 * TODO: Add more props for customizing
 */
export function ClipboardText({
  text,
  displayText = false,
  isTruncated = true,
  letterDisplayed = 3,
  icon = AiOutlineCopy,
  ...rest
}: Props) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };
  const renderText = (): React.ReactNode => {
    if (isTruncated && text?.toString()?.length > letterDisplayed) {
      return <UiText {...rest}>{text?.toString()?.substring(0, letterDisplayed)}... </UiText>;
    }
    return <UiText {...rest}>{text}</UiText>;
  };

  if (!displayText) {
    return (
      <UiTooltip label={text}>
        <Box>
          <UiIcon cursor="pointer" as={isCopied ? IoMdCopy : icon} boxSize="4" onClick={() => handleCopy()} />
        </Box>
      </UiTooltip>
    );
  }

  return (
    <Row align="center">
      <UiTooltip label={text}>
        <Box>{renderText()}</Box>
      </UiTooltip>
      <UiIcon cursor="pointer" as={isCopied ? IoMdCopy : icon} boxSize="6" onClick={() => handleCopy()} />
    </Row>
  );
}
